import {RouterProvider} from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "./router";
import { store } from "./store/store";
import {useEffect} from "react";
import axios from "axios";

const App = () => {
    useEffect(()=>{
        const refreshTokenSet=async ()=>{
            if(window.location.search!==""){
            const refresh=window.location.search.split("=")[1]
            await localStorage.setItem("token_refresh",refresh)
            await refreshtoken()
            
            }
        }
        refreshTokenSet()
    },[])

    const refreshtoken = async () => {
        const refresh_Token = await localStorage.getItem('token_refresh');
        try {
            const {data, status} = await axios.post('authentication/token/refresh/', {
                refresh: refresh_Token,
            });
            if (status === 200 || status === 201) {
                await localStorage.setItem('token_access', data.access);
                console.log("ok")
            }
        } catch (error) {
           console.log(error)
        }
    };


    // useEffect(() => {
    //     let refresh = localStorage.getItem("token_refresh");
    //     // let access = localStorage.getItem("token_access");
    //     if (refresh) {
    //         const interval = setInterval(() => {
    //             refreshtoken();
    //         }, 82800000);
    //         return () => clearInterval(interval);
    //     }
    // }, []);

    return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
