import { LoadingSkeleton, SearchBarButton } from "../../components";
import styled from "styled-components";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { findfullimage } from "../../utils/findfullimage";
import { toast } from "react-toastify";
import moment from "jalali-moment"
import { InputDatePicker }  from "jalaali-react-date-picker";
import "jalaali-react-date-picker/lib/styles/index.css";

// import { DatePicker } from "@react-shamsi/datepicker";
// import "@react-shamsi/calendar/dist/styles.css";
// import "@react-shamsi/datepicker/dist/styles.css";
// // If you want to use the time picker
// import "@react-shamsi/timepicker/dist/styles.css";

const CategoryProducts = () => {
  const navigate = useNavigate();

  const [shops, setShops] = useState([]);
  const [category, setCategory] = useState([]);

  const [products, setProducts] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [loadingShops, setLoadingShops] = useState(true);
  const [waterRight,setWaterRight] = useState([]);
  const [address, setAddress] = useState();
  const [pelak, setPelak] = useState();
  const [btnLoading, setBtnLoading] = useState(false)
  const [isAddressRegisterd, setIsRegistered] = useState(0)// 0 none request 1 -> true 2 ->false
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)
  const [payCost, setPayCost] = useState()
  const [waterPrice, setWaterPrice] = useState(0)


  useEffect(() => {
    // shopsRefreshToken();
    // productsRefreshToken();
    checkRegisterRefreshToken();
    getWaterPrice();
  }, []);

  const productPage = () => {
    navigate("/category/products");
  };
  const shopPage = () => {
    navigate("/category/shops");
  };

 

 //
 const onChangePelak =(e)=>{
    setPelak(e.target.value);
 }
 const onChangeAddress =(e)=>{
    setAddress(e.target.value);
 }


  const registerWaterRightsRefreshToken = async () => {
    // setLoadingCategory(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        registerWaterRights();
      }
    } catch (error) {}
  };

  const registerWaterRights = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    form.append("address", address);
    form.append("building_number", pelak);
    setBtnLoading(true)
  
    axios({
      method: "post",
      url: "waterrights/",
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // setLoadingCategory(false);
        // const itemCategory = response.data.filter((item) => item.banner === 6);

        setWaterRight(response.data);
        setBtnLoading(false)
        toast.success("آدرس  شما با موفقیت ایجاد شد!");
        setIsRegistered(1)
      })
      .catch((error) => {
        // setLoadingCategory(false);
        toast.error(error.response.data.detail);
        if (error && error.response.status === 401) {
          registerWaterRightsRefreshToken();
          
        }
      });
  };

 

  const getWaterPriceRefreshToken = async () => {
    // setLoadingCategory(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getWaterPrice();
      }
    } catch (error) {}
  };

  const getWaterPrice = () => {
    let token = localStorage.getItem("token_access");
  
    axios({
      method: "get",
      url: "waterrightsinfo/",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // setLoadingCategory(false);
        // const itemCategory = response.data.filter((item) => item.banner === 6);

        setWaterPrice(response.data[0].water_price);
        // alert(response.data[0].water_price)

      })
      .catch((error) => {
        // setLoadingCategory(false);
        if(error && error.response){
          toast.error(error.response.data.detail);
        }
        
        if (error && error.response && error.response.status === 401) {
          getWaterPriceRefreshToken();
          
        }
      });
  };


    const checkRegisterRefreshToken = async () => {
    // setLoadingCategory(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        checkRegister();
      }
    } catch (error) {}
  };

  const checkRegister = () => {
    let token = localStorage.getItem("token_access");
 
    axios({
      method: "get",
      url: "waterrights/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setLoadingCategory(false);
        // const itemCategory = response.data.filter((item) => item.banner === 6);
        console.log("in resp",response)
        setWaterRight(response.data);
        if(response.data.length === 0 ){
          console.log("response empty")
          setIsRegistered(2)
        }else{
          console.log("response not empty")
        }
        
      })
      .catch((error) => {
        // setLoadingCategory(false);

        if (error && error.response && error.response.status === 401) {
          checkRegisterRefreshToken();
        }
      });
  };

    const resWaterrightsRefreshToken = async () => {
    // setLoadingCategory(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        resWaterRights();
      }
    } catch (error) {}
  };

  const resWaterRights = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    // console.log(startDate)
    // return;
    let dt1 = startDate._d
    let dt2 = endDate._d
    // let jalaliDate1 = moment(dt1, 'jYYYY-jMM-jDD---');
    // console.log(startDate)
    // console.log(jalaliDate1)
    // return;
    dt1 = new Date(dt1);
    let djangoDateFormat1 = dt1.toISOString();
     dt2 = new Date(dt2);
    let djangoDateFormat2 = dt2.toISOString();
    // `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`;
    // console.log(djangoDateFormat1);

    // return;
    // let gregorianDate1 = dt1.format('YYYY-MM-DD');
    // console.log(gregorianDate1)
    // return;
    // let isoDate1 = gregorianDate1.toISOString();
    // let jalaliDate2 = moment(dt2, 'jYYYY-jMM-jDD');
    // let gregorianDate2 = jalaliDate2.format('YYYY-MM-DD');
    // console.log(gregorianDate2)
    // return;
    // let isoDate2 = gregorianDate2.toISOString();
    form.append("from_date", djangoDateFormat1);
    form.append("to_date", djangoDateFormat2);
    setBtnLoading(true)
 
    axios({
      method: "post",
      url: "reswaterrights/",
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // setLoadingCategory(false);
        // const itemCategory = response.data.filter((item) => item.banner === 6);

        setWaterRight(response.data);
        toast.success("درخواست شما ثبت شد");
        setBtnLoading(false)
        navigate("/profile");
        
      })
      .catch((error) => {
        // setLoadingCategory(false);
        toast.error(error && error.response.data.detail);
        if (error.response.status === 401) {
          resWaterrightsRefreshToken()
        }
      });
  };
  const calPriceWater = (date1,date2,priceWater)=>{
    let dt1 = new Date(date1)
    let dt2 = new Date(date2)
    let dif_dt = dt2- dt1;
    let cost = 0
    if(dt2 - dt1 < 1 || dt1 ===0 || dt2===0){
      return "---"
    }
    let dif_dt2 = dif_dt / (1000 * 60 * 60 * 24);
    cost = priceWater*dif_dt2
    return  cost 
    // *priceWater
  }
  return (
    <Wapper>
    
   {
    console.log(isAddressRegisterd) ||
    isAddressRegisterd===2?(
      <div className="py-3 px-2">
        <h1 className="text-font-slider mx-2 mb-1">ثبت نام</h1>
         <p className="f-12 mx-2">برای رزرو حق آب ابتدا ثبت نام کنید</p>

        <div className="container text-center ">
          <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 m-0">
          <label>
             آدرس<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeAddress}
          />
          </div>
           <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 m-0">
          <label>
             شماره پلاک<span className="text-danger"></span>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={onChangePelak}
          />
          </div>   
          <br/>
          <br/>
          {btnLoading? (
            <button
              className=" col btn btn-outline-primary btn-edit"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="col  btn btn-primary btn-edit"
              onClick={()=>registerWaterRights()}
            >
              ثبت نام
            </button>
          )}    
        </div>
      </div>
   ):(
  <div className="py-3 px-2">
        <h1 className="text-font-slider mx-2 mb-1">رزرو حق آب</h1>
         <p className="f-12 mx-2">برای ثبت درخواست تاریخ را وارد کنید</p>
         <p className="f-12 mx-2">  تایید در خواست شما ،از طریق پیامک یا از طریق منوی تاریخچه رزرو به اطلاع شما خواهد رسید</p>
         <br/><br/>
              <div className="container text-center ">
                  <div className="row row-cols-12 row-cols-lg-12 g-2 g-lg-3 m-0">
                  <label>
                  تاریخ شروع<span className="text-danger">*</span>
                  </label>
                  <InputDatePicker
                    value={startDate}
                    readOnly={true}
                    onChange={(date) => {
                    
                    setStartDate(date)}} />
                </div>
              </div>

            <div className="container text-center ">
                  <div className="row row-cols-12 row-cols-lg-12 g-2 g-lg-3 m-0">
                  <label>
                  تاریخ پایان<span className="text-danger">*</span>
                  </label>
                  <InputDatePicker 
                    value={endDate}
                    readOnly={true}
                    onChange={(date) => setEndDate(date)}
                     />
                </div>
              </div>
              <br/>
              <div className="container text-center ">
                  <div className="row row-cols-12 row-cols-lg-12 g-2 g-lg-3 m-0">
                  <label className="">
                  مبلغ قابل پرداخت<span className="text-danger"></span>
                  </label>
                  <p>{calPriceWater(startDate,endDate,waterPrice)}ریال</p>
                </div>
              </div>
              <br/>
               {btnLoading? (
          <div className="container text-center ">
          
            <button
              className=" col btn btn-outline-primary btn-edit"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            </div>
          ) : (
            <div className="container text-center ">
            <button
              className="col  btn btn-primary btn-edit"
              onClick={()=>resWaterRights()}
            >
              ثبت درخواست
            </button>
            </div>
            
          )}          
    </div>

   )}
    </Wapper>
  );
};

export default CategoryProducts;

const Wapper = styled.section`
  margin-bottom: 79px;
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }

  .card-category {
    background-color: #f5f5f5;
    height: 120px;
    width: 120px;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
  }

  .line {
    height: 0.5rem;
    margin: 0;
    background-color: #ccc;
    border-top: none;
  }
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }
  .img-banner3 {
    width: 95%;
    height: 100px;
    object-fit: contain;
  }
`;
