import ListGroup from "react-bootstrap/ListGroup";
import { ItemAddress, HeaderProfile } from "../../components";

import { FaArrowRight } from "react-icons/fa";

import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Address = () => {
  const navigate = useNavigate();

  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    addressRefreshToken();
  }, []);

  const newAddress = () => {
    navigate("/profile/address/new-address", { state: "addresspage" });
  };

  const addressRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getAddress();
      }
    } catch (error) {}
  };

  const getAddress = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shopuseraddress/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        setAddresses(response.data.reverse());
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          addressRefreshToken();
        }
      });
  };

  return (
    <div className="addressDiv">
      <HeaderProfile title_page="آدرس های من" />
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : addresses.length === 0 ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <h4 className="text-secondary">آدرسی ثبت نکرده اید!</h4>
        </div>
      ) : (
        
        <ListGroup>
          {addresses?.map((item, index) => (
            <ItemAddress
              key={index}
              title={item.title}
              status={false}
              address={item.address}
              number={item.delivery_receiverPhone}
              post_code={item.postal_code}
              unit_code={item.unit_number}
              plak={item.building_number}
              to={`/profile/address/edit/${item.id}`}
              edit={true}
            />
          ))}
        </ListGroup>
        
      )}

      <div className="d-flex justify-content-center ">
        <button className=" text-light addressBtn " onClick={newAddress}>
          ثبت آدرس جدید
        </button>
      </div>
    </div>
  );
};

export default Address;
