import { BaseApi } from './BaseApi'

const extendedSlideShowApi = BaseApi.injectEndpoints({
    tagTypes: ["SLIDER"],
    endpoints:  (builder)=>({
        getSliderShow: builder.query({
            query: () => 'slideshow/',
            providesTags: (result, error, id) => [{ type: 'SLIDER', id }],
        }),
    }),
    overrideExisting: false,
})

export const { useGetSliderShowQuery  } = extendedSlideShowApi