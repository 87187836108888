import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const LoadingSkeleton = ({
                             height,
                             width,
                             circle,
                             borderRadius,
                             className,
                             containerClassName,
                         }) => {
    return (
        <Skeleton
            height={height}
            width={width}
            circle={circle}
            className={className}
            containerClassName={containerClassName}
            borderRadius={borderRadius}
            baseColor="#e1e1e1"
            highlightColor="#d9d6d6"
        />
    )
}
export default LoadingSkeleton