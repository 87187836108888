import { IoIosArrowBack } from "react-icons/io";
import { HeaderProfile } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { MdOutlineInfo } from "react-icons/md";
import axios from "axios";
import { useEffect, useState } from "react";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { toast } from "react-toastify";
import AddressCheckBox from "../AddressCheckBox/AddressCheckBox";

const TimeSendOrderShop = () => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [selectCheckBoxAddress, setSelectCheckBoxAddress] = useState("");
  const [selectCheckBoxAddressId, setSelectCheckBoxAddressId] = useState("");
  const [basket, setBasket] = useState([]);
  const { state } = useLocation();
  const [basketLoading, setBasketLoading] = useState(true);
  const [allAddress, setAllAddress] = useState([]);

  useEffect(() => {
    addressRefreshToken();
    getBasketRefreshToken();
  }, []);

  const payMent = () => {
    if (selectCheckBoxAddress === "آدرسی موجود نیست!") {
      toast.error("لطفا آدرس خود را اضافه کنید!");
    } else {
      
      navigate("/orders/payment" , {state:{address_id: state !== null ? state.id : selectCheckBoxAddressId}});
    }
  };

  ////
  const ckeckBoxAddress = () => {
    if (allAddress.length === 0) {
      navigate("/profile/address/new-address", { state: "timesendpage" });
    } else {
      navigate("/orders/checkout/edit");
    }
  };
  ////

  const addressRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getAddress();
      }
    } catch (error) {}
  };

  const getAddress = async () => {
    let token = await localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shopuseraddress/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAllAddress(response.data);

        if (response.data.length === 0) {
          setSelectCheckBoxAddress("آدرسی موجود نیست!");
        } else {
          setSelectCheckBoxAddress(response.data[0].address);
          setSelectCheckBoxAddressId(response.data[0].id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          addressRefreshToken();
        }
      });
  };

  ////
  const getBasketRefreshToken = async () => {
    setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getBasket();
      }
    } catch (error) {}
  };

  const getBasket = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shoppingcart/`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setBasketLoading(false);
        setBasket(response.data);
      })
      .catch((error) => {
        setBasketLoading(false);

        if (error.response.status === 401) {
          getBasketRefreshToken();
        }
      });
  };
  /////


  /////

  return (
    <>
      <HeaderProfile title_page="تعیین آدرس" />
      <div className="p-2 ">
        <div
          className="bg-light p-1"
          style={{ height: "30px", fontSize: "12px" }}
        >
          <p>
            سبد خرید
            <IoIosArrowBack />
            <span style={{ color: "#b400ae" }}>تعیین آدرس</span>
            <IoIosArrowBack />
            پرداخت
          </p>
        </div>
        {basketLoading ? (
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ height: "50vh" }}
          >
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div
              className="p-2"
              style={{ fontSize: "12px", marginBottom: "150px" }}
            >
              <div
                className="d-flex justify-content-between align-items-center pt-4 px-2"
                style={{ fontSize: "13px" }}
              >
                <p className="m-0 text-secondary">آدرس تحویل سفارش</p>

                <button
                  className="btn btn-outline-primary "
                  style={{ fontSize: "12px" }}
                  onClick={ckeckBoxAddress}
                >
                   ایجاد یا ویرایش آدرس
                </button>
              </div>

              <hr
                style={{ width: "93%", border: "1px solid #767676" }}
                className="mx-auto"
              />
              <div className="row px-2 m-0">
                <div className="col-1 text-secondary">
                  <GrLocation size="20px" />
                </div>
                <div className="col">
                  <p className="m-0">ارسال به</p>
                  <p>
                    {state !== null ? state.address : selectCheckBoxAddress}
                  </p>
                </div>
              </div>

              <div className="px-3 py-2 mt-3 shadow">
                <p className="fw-bold m-0" style={{ fontSize: "15px" }}>
                  اطلاعات پرداخت
                </p>
                <p>(تمامی قیمت ها به ریال میباشد)</p>
                <div className="d-flex justify-content-between">
                  <p>
                  قیمت کالاها <span>({basket[0].product_count})</span>

                  </p>
                  <p className="fw-bold">
                    {numberWithCommas(basket[0].total_price)} ریال
                  </p>
                </div>
                <hr
                  style={{ width: "93%", border: "1px dashed #292929" }}
                  className="mx-auto"
                />


                 <div className="d-flex justify-content-between">
                  <p className="m-0">
                    هزینه ارسال
                  </p>
                  <p className="fw-bold m-0">
                    {numberWithCommas(basket[0].shipping_fee)} ریال
                  </p>
                </div>
                <hr
                  style={{ width: "93%", border: "1px dashed #292929" }}
                  className="mx-auto"
                />

                <div className="d-flex justify-content-between mb-3">
                  <p className="m-0">قابل پرداخت</p>
                  <p className="m-0 fw-bold">
                    {numberWithCommas(basket[0].total_price + basket[0].shipping_fee)} ریال
                  </p>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-between px-3 py-2 align-items-center bg-white w-100 shadow-lg"
              style={{ position: "fixed", bottom: "70px", left: "0px" }}
            >
              <div>
                <p className="m-0">قابل پرداخت</p>
                <p className="m-0 fw-bold">
                  {" "}
                  {numberWithCommas(basket[0].total_price)} ریال
                </p>
              </div>
              <button
                className="btn btn-red "
                style={{ padding: "10px 25px" }}
                onClick={payMent}
              >
                پرداخت
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TimeSendOrderShop;
