import {IoMdSearch} from "react-icons/io";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SearchBarButton = () => {
    const navigation = useNavigate();

    const navigateToSearch=()=>{
        navigation('/search')
    }

    return (
        <Wapper>
            <div className="elevation-on-bottom">
                <div className="w-100 py-2 px-1 border-search-input">
                    <button className="border-0 bg-transparent w-100" onClick={navigateToSearch}>
                        <div className="d-flex m-0 justify-content-start align-items-center">
                            <IoMdSearch color="#9e9e9e" size={"1.5em"}/>
                            <p className="m-0 text-end f-12 px-2 text-secondary">جستجوی خدمات</p>
                        </div>
                    </button>
                </div>
            </div>
        </Wapper>
    )
}

export default SearchBarButton


const Wapper = styled.section`
  //search input
  .elevation-on-bottom {
    box-shadow: 0 2px 4px rgb(85 85 85 / 20%);
    padding: 5px 10px 5px 10px;
  }

  .text-input-search {
    border: none;
    width: 100%;
    background-color: transparent;
  }

  .text-input-search:focus-visible {
    border: none;
  }

  .border-search-input {
    position: relative;
    border-radius: 5px;
    background-color: #f5f5f5;
    height: 2.5rem;
  }

  //search input
`