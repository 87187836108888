import { datachangemoment } from "../../utils/datachangemoment";
import { FaPhoneFlip, FaRegCircleCheck } from "react-icons/fa6";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { BsSendFill } from "react-icons/bs";

const OrderMyShopHistory = ({ arrayorder }) => {
  const { shopid } = useParams();

  return (
    <>
      {arrayorder.map((item, index) => (
        <Link
          to={`/profile/myshop/${shopid}/myordershophistory/${item.id}`}
          style={{ textDecoration: "none", color: "#292929" }}
        >
          <div className="my-3">
            <div
              className=" mx-3 p-2"
              key={index}
              style={{
                border: "1px solid #ccc",
                fontSize: "12px",
                borderRadius: "5px",
              }}
            >
              <div className="row m-0 w-100">
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">سفارش : {item.id}</p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3 ">
                  {" "}
                  <p className="m-1">{datachangemoment(item.created_at)}</p>
                </div>

                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    <FaPhoneFlip /> {item.user}
                  </p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    {numberWithCommas(item.total_price)} ریال
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6">
                  <p className="m-1">
                    <MdLocationOn size="15px" />
                    {item.address.address}
                  </p>
                </div>
                {item.reason_for_rejection !== null  && (
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6">
                    <p className="m-1">دلیل  لغو : {item.reason_for_rejection}</p>
                  </div>
                )}
                <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 text-start">
                  {item.status === 2 ? (
                    <p className="m-1">
                      <IoMdCloseCircleOutline
                        className="text-danger"
                        size="1.5em"
                      />{" "}
                      رد شده
                    </p>
                  ) : item.status === 3 ? (
                    <p className="m-1">
                      {" "}
                      <FaRegCircleCheck
                        className="text-success"
                        size="1.3em"
                      />{" "}
                      تایید شده
                    </p>
                  ) : item.status === 4 ? (
                    <p className="m-1">
                      {" "}
                      <BsSendFill className="text-primary" size="1.3em" /> ارسال
                      شده
                    </p>
                  ) : item.status === 5 ? (
                    <p className="m-1">
                      {" "}
                      <TbTruckDelivery
                        className="text-info"
                        size="1.5em"
                      />{" "}
                      تحویل داده شده
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default OrderMyShopHistory;
