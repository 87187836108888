import { AiOutlineCloseCircle } from "react-icons/ai";
import { datachangemoment } from "../../utils/datachangemoment";
import { GoIssueClosed } from "react-icons/go";
import { FaPhoneFlip } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { numberWithCommas } from "../../utils/numberWithCommas";

const OrderMyShop = ({ arrayorder }) => {
  const { shopid } = useParams();


  return (
    <>
      {arrayorder.map((item, index) => (
        <Link
          to={`/profile/myshop/${shopid}/myordershop/${item.id}`}
          style={{ textDecoration: "none", color: "#292929" }}
        >
          <div className="my-3">
            <div
              className=" mx-3 p-2"
              key={index}
              style={{
                border: "1px solid #ccc",
                fontSize: "12px",
                borderRadius: "5px",
              }}
            >
              <div className="row m-0 w-100">
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">سفارش : {item.id}</p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3 ">
                  {" "}
                  <p className="m-1">{datachangemoment(item.created_at)}</p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    <FaPhoneFlip /> {item.user}
                  </p>
                </div>{" "}
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    {numberWithCommas(item.total_price)} ریال
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6">
                  <p className="m-1">
                    <MdLocationOn size="15px" />
                    {item.address.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default OrderMyShop;
