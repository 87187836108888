import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import { Rating } from "react-simple-star-rating";

import { LoadingSkeleton } from "../../components";
import { toast } from "react-toastify";
import { BsShop } from "react-icons/bs";
import { useKeenSlider } from "keen-slider/react";
import "./style.css";
import { defultimage } from "../../utils/defultimage";

const AdminProduct = () => {
  const [basket, setBasket] = useState(null);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const { productid } = useParams();
  const { shopid } = useParams();
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    rtl: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    productRefreshToken();
  }, []);

  const productRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productShop();
      }
    } catch (error) {}
  };

  const productShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `servicejobsample/${productid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);

        setProduct(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          productRefreshToken();
        }
      });
  };

  const navigateEdit = () => {
    navigate(`/profile/myshop/${shopid}/editmyproduct/${productid}`, {
      state: { value: "productpage" },
    });
  };

  return (
    <div>
      {loading ? (
        <>
          <div className="mt-5">
            <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
          </div>

          <div className="mx-3  mt-5 ">
            <div className="d-flex justify-content-between mb-3">
              <LoadingSkeleton height="10px" width="200px" borderRadius={4} />
              <div>
                <LoadingSkeleton height="10px" width="30px" borderRadius={4} />
              </div>
            </div>
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="50%" borderRadius={4} />
          </div>
        </>
      ) : (
        <div style={{ marginBottom: "150px" }}>
          <div>
            <div className="navigation-wrapper">
            {defultimage(
            product.image1,
            product.image2,
            product.image3,
            product.image4,
            product.image5
          ) ? (
            <div style={{ height: "250px" }}>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  <div
                    className="keen-slider__slide  "
                    style={{ height: "250px" }}
                  >
                    <img
                      src={window.location.origin + `/image/no_image.png`}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: "250px" }}>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  {product.image1 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image1}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image2 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image2}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image3 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image3}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image4 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image4}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image5 !== null ? (
                    <div
                      className="keen-slider__slide "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image5}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
                {loaded && instanceRef.current && (
                  <div className="dotsproducts">
                    {[
                      ...Array(
                        instanceRef.current.track.details.slides.length
                      ).keys(),
                    ].map((idx) => {
                      return (
                        <button
                          key={idx}
                          onClick={() => {
                            instanceRef.current?.moveToIdx(idx);
                          }}
                          className={
                            "dotproducts" +
                            (currentSlide === idx ? " activee" : "")
                          }
                        ></button>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

            </div>
     </div>

          <div className="mx-3  mt-5">

            <p>
              <BsShop />{" "}
              {/* {product.shop.title} */}
            </p>
            <p className="text-muted" style={{ textAlign: "justify" }}>
              {product.description}
            </p>
          </div>

       
        </div>
      )}
    </div>
  );
};

export default AdminProduct;
