import { useParams } from "react-router-dom";
import { ItemPersonalShope, LoadingSkeleton } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { FaRegClock } from "react-icons/fa";
import { LuCalendarDays } from "react-icons/lu";
import { FaPhoneFlip } from "react-icons/fa6";
import { MdReport } from "react-icons/md";

const PersonalShope = () => {
  const { shopid } = useParams();
  const [shop, setShop] = useState(null);
  const [reportDesc, setReportDesc] = useState("");
  const [product, setProduct] = useState([]);
  const [loadingShop, setLoadingShop] = useState(true);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const[reportReview,setReportReview]=useState(false)

  useEffect(() => {
    shopRefreshToken();
    productRefreshToken();
    getcomplaintRefreshToken();
    setReportReview(false);
  }, []);
  const onChangeReportText = (e)=>{
    setReportDesc(e.target.value);
  }
  const shopRefreshToken = async () => {
    setLoadingShop(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShop();
      }
    } catch (error) {}
  };

  const getShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `/servicejob/${shopid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShop(false);

        setShop(response.data);
      })
      .catch((error) => {
        setLoadingShop(false);

        if (error.response.status === 401) {
          shopRefreshToken();
        }
      });
  };

  const productRefreshToken = async () => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productShop();
      }
    } catch (error) {}
  };

  const productShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `returnsamplebyservicejob/${shopid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingProduct(false);

        setProduct(response.data);
      })
      .catch((error) => {
        setLoadingProduct(false);

        if (error.response.status === 401) {
          productRefreshToken();
        }
      });
  };


  const getCompaint = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    axios({
      method: "get",
      url: `servicejobcomplaint/`,
      data:form,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        response.data.map((item,index)=>{
          if(item.serviceJob == `${shopid}`){
            setReportReview(true)
          }
        })
        
      })
      .catch((error) => {
        setLoadingProduct(false);

        if (error.response.status === 401) {
          getcomplaintRefreshToken()
        }
      });
  };

  const getcomplaintRefreshToken = async () => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getCompaint()
      }
    } catch (error) {}
  };


  const sendCompaint = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    form.append("description", reportDesc);
    form.append("servicejob_id", shopid);
    axios({
      method: "post",
      url: `servicejobcomplaint/`,
      data:form,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setReportReview(true)
      })
      .catch((error) => {
        setLoadingProduct(false);

        if (error.response.status === 401) {
          complaintRefreshToken()
        }
      });
  };

  const complaintRefreshToken = async () => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        sendCompaint();
      }
    } catch (error) {}
  };

  return (
    <div style={{ marginBottom: "75px" }}>
      {loadingShop ? (
        <div className="m-2" style={{ marginBottom: "100px" }}>
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div>
              <LoadingSkeleton height="10px" width="80px" borderRadius={4} />
            </div>
            <div>
              <LoadingSkeleton height="70px" width="70px" circle={true} />
            </div>
          </div>

          <div className="m-2 px-2  py-4">
            <LoadingSkeleton height="10px" width="150px" borderRadius={4} />
            <LoadingSkeleton height="10px" width="170px" borderRadius={4} />
          </div>
          <div>
            <LoadingSkeleton height="50px" width="100%" borderRadius={5} />
          </div>
        </div>
      ) : (
        <div className="m-2" style={{ marginBottom: "100px" }}>
          <div className="p-2 d-flex justify-content-between">
            <p className=" m-0 d-flex align-items-center justify-content-center">
              {shop.title}
            </p>

            <img
              src={shop.image}
              alt=""
              className="rounded-circle shadow"
              style={{ width: "70px", height: "70px", objectFit: "cover" }}
            />
          </div>

          <div className="m-2 px-2  py-4">
            <p style={{ fontSize: "12px" }}>
              <LuCalendarDays className="ms-2 text-secondary" size="17px" />
              روزهای کاری :{" "}
              {shop.workingDaysStart === shop.workingDaysEnd ? (
                "هر روز"
              ) : (
                <>
                  {shop.workingDaysStart === 1
                    ? "شنبه"
                    : shop.workingDaysStart === 2
                    ? "یکشنبه"
                    : shop.workingDaysStart === 3
                    ? "دوشنبه"
                    : shop.workingDaysStart === 4
                    ? "سه شنبه"
                    : shop.workingDaysStart === 5
                    ? "چهارشنبه"
                    : shop.workingDaysStart === 6
                    ? "پنج شنبه"
                    : "جمعه"}{" "}
                  تا{" "}
                  {shop.workingDaysEnd === 1
                    ? "شنبه"
                    : shop.workingDaysEnd === 2
                    ? "یکشنبه"
                    : shop.workingDaysEnd === 3
                    ? "دوشنبه"
                    : shop.workingDaysEnd === 4
                    ? "سه شنبه"
                    : shop.workingDaysEnd === 5
                    ? "چهارشنبه"
                    : shop.workingDaysEnd === 6
                    ? "پنج شنبه"
                    : "جمعه"}
                </>
              )}
            </p>

            <p style={{ fontSize: "12px" }}>
              <FaRegClock className="ms-2 text-secondary" size="17px" />
              ساعت کاری : {shop.workingHoursStart.substring(0, 5)} تا{" "}
              {shop.workingHoursEnd.substring(0, 5)}
            </p>
          </div>
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-outline-secondary w-50 mx-1"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#contactcanvasBottom"
              aria-controls="contactcanvasBottom"
            >
              <FaPhoneFlip /> تماس با فروشگاه
            </button>
            <button
              className="btn btn-outline-danger w-50 mx-1"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#reportcanvasBottom"
              aria-controls="reportcanvasBottom"
            >
              <MdReport size="20px" /> گزارش تخلف
            </button>
          </div>
        </div>
      )}
      <div className="mt-5">
        <div className="row m-0 g-1">
          {loadingProduct ? (
            <>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
            </>
          ) : product.length === 0 ? (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ height: "40vh" }}
            >
              <h4 className="text-secondary">نمونه ای وجود ندارد</h4>
            </div>
          ) : (
            product?.map((item, index) => (
              <ItemPersonalShope
                key={index}
                image1={item.image1}
                image2={item.image2}
                image3={item.image3}
                image4={item.image4}
                image5={item.image5}
                title={item.title}
                // price={numberWithCommas(item.price)}
                to={`/category/product/${item.id}`}
                // rate={item.average_rating}
              />
            ))
          )}
        </div>
      </div>

      <div
        className="offcanvas offcanvas-bottom h-25"
        id="reportcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-danger" id="offcanvasBottomLabel">
            گزارش تخلف
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        {reportReview?(<p className="px-2">شکایت شما در مورد این فروشگاه ثبت شده و درحال رسیدگی به آن هستیم</p>):(
        <div className="offcanvas-body small">
          <textarea onChange={onChangeReportText} style={{border:"1px solid #ccc"}} placeholder="متن گزارش"></textarea>
          <br/><br/>
          <button
              className="btn btn-secondary w-50 mx-1"
              type="button"
              onClick={()=>sendCompaint()}
            >
               ثبت گزارش
            </button>

        </div>
        )}
      </div>

      <div
        className="offcanvas offcanvas-bottom h-25"
        id="contactcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasBottomLabel">
            راه های ارتباطی با فروشگاه
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body small">
          {shop !== null && (
            <>
              <p> شماره تماس : {shop.phoneNumber}</p>
              <p>آدرس : {shop.address}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalShope;
