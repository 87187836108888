import { HeaderProfile, OrderMyShop, OrderMyShopHistory } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";

const AdminOrder = () => {
  
  const [ordersDuring, setOrdersDuring] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);

  ///
  useEffect(() => {
    ordersRefreshToken();
  }, []);

  ///

  const ordersRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getOrder();
      }
    } catch (error) {}
  };

  const getOrder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shoporder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        setOrdersDuring(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          ordersRefreshToken();
        }
      });
  };


  /////ordershistory

  const ordersHistoryRefreshToken = async () => {
    setLoadingHistory(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getOrderHistory();
      }
    } catch (error) {}
  };

  const getOrderHistory = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shoporderhistory/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingHistory(false);
        setOrdersHistory(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setLoadingHistory(false);

        if (error.response.status === 401) {
          ordersHistoryRefreshToken();
        }
      });
  };


  return (
    <>
      <HeaderProfile title_page="سفارش های من" />
      <ul class="nav nav-pills nav-buy " id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-During-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-During"
            type="button"
            role="tab"
            aria-controls="pills-During"
            aria-selected="true"
            onClick={ordersRefreshToken}
            
          >
            سفارشات جاری
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-history-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-history"
            type="button"
            role="tab"
            aria-controls="pills-history"
            aria-selected="false"
            onClick={ordersHistoryRefreshToken}
          >
            {" "}
            سفارشات سابق
          </button>
        </li>
      </ul>



      <div
        style={{
          marginBottom: "100px",
          marginTop: "40px",
        }}
      >
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-During"
            role="tabpanel"
            aria-labelledby="pills-During-tab"
          >
            {loading ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div class="spinner-border text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : ordersDuring.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما در حال حاضر سفارشی ندارید!</p>
              </div>
            ) : (
              <OrderMyShop arrayorder={ordersDuring} />
            )}
          </div>
          <div
            class="tab-pane fade"
            id="pills-history"
            role="tabpanel"
            aria-labelledby="pills-history-tab"
          >
            {loadingHistory ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div class="spinner-border text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : ordersHistory.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما سفارشی تا کنون نداشته اید!</p>
              </div>
            ) : (
              <OrderMyShopHistory arrayorder={ordersHistory} />
            )}
          </div>
          </div>
          </div>





{/* 
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : orders.length === 0 ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "70vh" }}
        >
          <p className="text-secondary">شما در حال حاضر سفارش ندارید!</p>
        </div>
      ) : (
        <OrderMyShop arrayorder={orders} />
      )} */}
    </>
  );
};

export default AdminOrder;
