import { useNavigate, useParams } from "react-router-dom";
import { AddressFilds, HeaderProfile } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const EditAddress = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [currentAddress, setCurrentAddress] = useState(null);
  const [address, setAddress] = useState(null);
  const [plak, setPlak] = useState(null);
  const [vahed, setVahed] = useState(null);
  const [title, setTitle] = useState(null);
  const [codePosti, setCodePosti] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading1, setBtnLoading1] = useState(false);
  const [btnLoading2, setBtnLoading2] = useState(false);

  useEffect(() => {
    if (currentAddress === null) {
      addressRefreshToken();
    } else {
      setAddress(currentAddress.address);
      setPlak(currentAddress.building_number);
      setVahed(currentAddress.unit_number);
      setTitle(currentAddress.title);
      setCodePosti(currentAddress.postal_code);
      setPhone(currentAddress.delivery_receiverPhone);
    }
  }, [currentAddress]);

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangePlack = (e) => {
    setPlak(e.target.value);
  };
  const onChangeVahed = (e) => {
    setVahed(e.target.value);
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeCodePosti = (e) => {
    setCodePosti(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const exitNavigate = () => {
    navigate("/profile/address");
  };

  // حذف  آدرس

  const deleteNavigateRefreshToken = async () => {
    setBtnLoading2(true);

    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        deleteNavigate();
      }
    } catch (error) {}
  };

  const deleteNavigate = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "delete",
      url: `shopuseraddress/${id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate("/profile/address");
        setBtnLoading2(false);

        toast.error("آدرس  شما با موفقیت حذف شد!");
      })
      .catch((error) => {});
  };

  //  ویرایش آدرس

  const editNavigateRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        sendRequestEdit();
      }
    } catch (error) {}
  };

  const sendRequestEdit = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `shopuseraddress/${id}/`,

      data: {
        title: title === "" ? null : title,
        address: address,
        postal_code: codePosti === "" ? null : codePosti,
        building_number: plak === "" ? null : plak,
        unit_number: vahed === "" ? null : vahed,
        delivery_receiverPhone: phone,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate("/profile/address");
        setBtnLoading1(false);

        toast.info("آدرس  شما با موفقیت ویرایش شد!");
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          } else if (error.response.data.delivery_receiverPhone) {
            toast.error(error.response.data.delivery_receiverPhone[0]);
          }
        }

        if (error.response.status === 401) {
          editNavigateRefreshToken();
        }
        setBtnLoading1(false);
      });
  };

  const editNavigate = () => {
    setBtnLoading1(true);
    if (address !== "" && address !== null && phone !== null && phone !== "") {
      editNavigateRefreshToken();
    } else {
      toast.error("فیلد آدرس  و شماره موبایل باید وارد شود!");
      setBtnLoading1(false);
    }
  };

  const addressRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getAddress();
      }
    } catch (error) {
      
    }
  };

  const getAddress = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shopuseraddress/${id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setCurrentAddress(response.data);
      
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          addressRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="ویرایش آدرس" />
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <AddressFilds
          btn1="ویرایش آدرس"
          btn2="حذف آدرس"
          createNavigate={editNavigate}
          exitNavigate={deleteNavigateRefreshToken}
          valueAddress={address}
          onChangeAddress={onChangeAddress}
          valuePlak={plak}
          onChangePlak={onChangePlack}
          valueVahed={vahed}
          onChangeVahed={onChangeVahed}
          valueTitle={title}
          onChangeTitle={onChangeTitle}
          valueCodePosti={codePosti}
          onChangeCodePosti={onChangeCodePosti}
          valuePhone={phone}
          onChangePhone={onChangePhone}
          btnLoading1={btnLoading1}
          btnLoading2={btnLoading2}
        />
      )}
    </>
  );
};

export default EditAddress;
