import { useLocation, useNavigate } from "react-router-dom";
import { AddressFilds, HeaderProfile } from "../../components";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const CreateAddress = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState(null);
  const [plak, setPlak] = useState(null);
  const [vahed, setVahed] = useState(null);
  const [title, setTitle] = useState(null);
  const [codePosti, setCodePosti] = useState(null);
  const [phone, setPhone] = useState(null);
  const { state } = useLocation();
  const [btnLoading1, setBtnLoading1] = useState(false);
  const [btnLoading2, setBtnLoading2] = useState(false);

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangePlack = (e) => {
    setPlak(e.target.value);
  };
  const onChangeVahed = (e) => {
    setVahed(e.target.value);
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeCodePosti = (e) => {
    setCodePosti(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const exitNavigate = () => {
    setBtnLoading2(true);

    if (state === "addresspage") {
      navigate("/profile/address");
    } else if (state === "timesendpage") {
      navigate("/orders/checkout");
    } else {
      navigate("/orders/checkout/edit");
    }
    setBtnLoading2(false);
  };

  const createNavigateRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        sendRequestCreate();
      }
    } catch (error) {}
  };

  const sendRequestCreate = () => {
    let token = localStorage.getItem("token_access");
    
    axios({
      method: "post",
      url: "shopuseraddress/",
      data: {
        title: title === "" ? null : title,
        address: address,
        postal_code: codePosti === "" ? null : codePosti,
        building_number: plak === "" ? null : plak,
        unit_number: vahed === "" ? null : vahed,
        delivery_receiverPhone: phone,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (state === "addresspage") {
          navigate("/profile/address");
        } else if (state === "timesendpage") {
          navigate("/orders/checkout");
        } else {
          navigate("/orders/checkout/edit");
        }
        setBtnLoading1(false);

        toast.success("آدرس  شما با موفقیت ایجاد شد!");
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          } else if (error.response.data.delivery_receiverPhone) {
            toast.error(error.response.data.delivery_receiverPhone[0]);
          }
        }

        if (error.response.status === 401) {
          createNavigateRefreshToken();
        }
        setBtnLoading1(false);
      });
  };

  const createNavigate = () => {
    setBtnLoading1(true);
    if (address !== "" && address !== null && phone !== null && phone !== "") {
      createNavigateRefreshToken();
    } else {
      toast.error(" فیلد آدرس  و شماره موبایل باید وارد شود!");
      setBtnLoading1(false);
    }
  };

  return (
    <>
      <HeaderProfile title_page="ثبت آدرس جدید" />
      <AddressFilds
        btn1=" ثبت آدرس جدید"
        btn2="انصراف"
        createNavigate={createNavigate}
        exitNavigate={exitNavigate}
        valueAddress={address}
        onChangeAddress={onChangeAddress}
        valuePlak={plak}
        onChangePlak={onChangePlack}
        valueVahed={vahed}
        onChangeVahed={onChangeVahed}
        valueTitle={title}
        onChangeTitle={onChangeTitle}
        valueCodePost={codePosti}
        onChangeCodePosti={onChangeCodePosti}
        valuePhone={phone}
        onChangePhone={onChangePhone}
        btnLoading1={btnLoading1}
        btnLoading2={btnLoading2}
      />
    </>
  );
};

export default CreateAddress;
