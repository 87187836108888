import { BaseApi } from './BaseApi'

const extendedOfferApi = BaseApi.injectEndpoints({
    tagTypes: ["OFFER"],
    endpoints:  (builder)=>({
        getOffer: builder.query({
            query: () => 'offer/',
            providesTags: (result, error, id) => [{ type: 'OFFER', id }],
        }),
    }),
    overrideExisting: false,
})

export const { useGetOfferQuery  } = extendedOfferApi