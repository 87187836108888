import Card2 from "../../assets/images/9b0f2106-740c-41fd-8b66-21fcb779bd63.webp";
import styled from "styled-components";

const BannerCartFourItem=({children})=>{
    return(
       <Wapper>
           <div className="container text-center p-3">
               <div className="row g-4">
                   {children}
               </div>
           </div>
       </Wapper>
    )
}

export default BannerCartFourItem

const Wapper=styled.section`

  //banner
 



  //banner

`