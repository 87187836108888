import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { numberWithCommas } from "../../utils/numberWithCommas";
import HeaderProfile from "../../components/HeaderProfile/HeaderProfile";
import "./style.css";
import { findfullimage } from "../../utils/findfullimage";

const ItemOrderMyShop = () => {
  const { orderid } = useParams();
  const { shopid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState("");
  const [orderItem, setOrderItem] = useState(null);

  useEffect(() => {
    ordersItemRefreshToken();
  }, []);

  ////

  const ordersItemRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        ordersItem();
      }
    } catch (error) {}
  };

  const ordersItem = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shoporder/${orderid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        setOrderItem(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          ordersItemRefreshToken();
        }
      });
  };

  ////confirmation
  const putConfirmationRefreshToken = async () => {
    // setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        putConfirmation();
      }
    } catch (error) {}
  };

  const putConfirmation = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `shoporder/${orderid}/`,
      data: {
        order_confirmation: true,
        order_rejection: false,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate(`/profile/myshop/${shopid}/myordershop`);
      })
      .catch((error) => {
        // setLoading(false);

        if (error.response.status === 401) {
          putConfirmationRefreshToken();
        }
      });
  };
  /////reject

  const putRejectRefreshToken = async () => {
    // setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        putReject();
      }
    } catch (error) {}
  };

  const putReject = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `shoporder/${orderid}/`,
      data: {
        order_confirmation: false,
        order_rejection: true,
        reason_for_rejection: reason,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate(`/profile/myshop/${shopid}/myordershop`);
      })
      .catch((error) => {
        // setLoading(false);

        if (error.response.status === 401) {
          putRejectRefreshToken();
        }
      });
  };

  /////
  const oClickPutConfirm = () => {
    putConfirmationRefreshToken();
    navigate(`/profile/myshop/${shopid}/myordershop`);
  };

  /////
  const oClickPutReject = () => {
    putRejectRefreshToken();
    navigate(`/profile/myshop/${shopid}/myordershop`);
  };

  return (
    <div style={{ marginBottom: "120px" }}>
      <HeaderProfile title_page={`محصولات سفارش`} />
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        orderItem.products.map((item, index) => (
          <>
            <div key={index} style={{ fontSize: "11px" }} className="mx-3">
              <div className="row row-cols-1 row-cols-md-3 g-2 my-2  ">
                <div className="col">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-4 d-flex align-items-center  ">
                      {findfullimage(
                          item.product.image1,
                           item.product.image2,
                          item.product.image3,
                          item.product.image4,
                          item.product.image5
                        ) !== false ? (
                          <img
                            src={"https://devapi.mykilan.ir" + findfullimage(
                              item.product.image1,
                              item.product.image2,
                              item.product.image3,
                              item.product.image4,
                              item.product.image5
                            )}
                            className="img-fluid rounded-1 m-1 "
                            alt="..."
                          />
                        ) : (
                          <img
                            src={window.location.origin + "/image/no_image.png"}
                            className="img-fluid rounded-1 m-1 "
                            alt="..."
                          />
                        )}
                      </div>
                      <div className="col-8">
                        <div className="card-body ">
                          <p
                            className="card-title"
                            style={{ fontSize: "17px" }}
                          >
                            {item.product.title}
                          </p>

                          <p className="card-text">{item.quantity} عدد</p>
                          <p className="card-text text-start">
                            {" "}
                            {numberWithCommas(item.product.price)} ریال
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      )}

      <div
        className="d-flex justify-content-center w-100 bg-white shadow"
        style={{
          position: "fixed",
          bottom: "68px",
          height: "60px",
          padding: "10px",
        }}
      >
        <button
          className="btn btn-success w-50 mx-1"
          style={{ fontSize: "13px" }}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#confirmcanvasBottom"
          aria-controls="confirmcanvasBottom"
        >
          {/* <GoIssueClosed size="13px" /> */}
          تایید سفارش
        </button>
        <button
          className="btn btn-danger w-50  mx-1"
          style={{ fontSize: "13px" }}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#rejectcanvasBottom"
          aria-controls="rejectcanvasBottom"
        >
          {/* <AiOutlineCloseCircle size="14px" /> */}
          رد سفارش
        </button>
      </div>

      <div
        className="offcanvas offcanvas-bottom h-25"
        id="confirmcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
        // style={{overflowY:'auto !important'}}
      >
        <div className=" row offcanvas-header">
          <div className="col-10">
            <p className="mt-3" id="offcanvasBottomLabel">
              آیا مایل به تایید سفارش هستید؟
            </p>
          </div>
          <div className="col-2 mt-1">
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div className="offcanvas-body small align-items-center justify-content-center d-flex">
          <button
            className="btn btn-success w-50 p-2"
            style={{ fontSize: "13px" }}
            aria-label="Close"
            data-bs-dismiss="offcanvas"
            onClick={oClickPutConfirm}
          >
            {" "}
            بله هستم
          </button>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-bottom h-50"
        id="rejectcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
        // style={{overflowY:'auto !important'}}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title " id="offcanvasBottomLabel">
            رد سفارش
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body small align-items-center justify-content-center d-flex flex-column ">
          <textarea
            onChange={(e) => setReason(e.target.value)}
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
            className="mb-3 w-100 pt-2 px-2 textarea-reject"
            placeholder="دلیل رد سفارش"
          />
          <button
            className="btn btn-danger w-50 p-2"
            style={{ fontSize: "13px" }}
            aria-label="Close"
            data-bs-dismiss="offcanvas"
            onClick={oClickPutReject}
          >
            رد سفارش
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemOrderMyShop;
