import { ListGroup } from "react-bootstrap";
import { HeaderProfile, OrderItem } from "../../components";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineInfo } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { numberWithCommas } from "../../utils/numberWithCommas";

const OrdersShop = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [basket, setBasket] = useState([]);
  const [basketLoading, setBasketLoading] = useState(true);

  const chekout = () => {
    navigate("/orders/checkout");
  };

  useEffect(() => {
    getBasketRefreshToken();
  }, []);

  //get basket

  const getBasketRefreshToken = async () => {
    setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getBasket();
      }
    } catch (error) {}
  };

  const getBasket = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shoppingcart/`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setBasketLoading(false);

        setBasket(response.data);
      })
      .catch((error) => {
        setBasketLoading(false);

        if (error.response.status === 401) {
          getBasketRefreshToken();
        }
      });
  };

  const plusRefreshToken = async (id) => {
    // setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        plus(id);
      }
    } catch (error) {}
  };

  const plus = (id) => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "post",
      url: `shoppingcart/`,
      data: {
        product_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setBasketLoading(true);
        getBasketRefreshToken();
      })
      .catch((error) => {
        // setBasketLoading(true);

        if (error.response.status === 401) {
          plusRefreshToken(id);
        }
      });
  };

  const onClickPlus = (id) => {
    plusRefreshToken(id);
  };

  const minusRefreshToken = async (id) => {
    // setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        minus(id);
      }
    } catch (error) {}
  };

  const minus = (id) => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `shoppingcart/removeproduct/`,
      data: {
        product_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setBasketLoading(true);
        getBasketRefreshToken();
      })
      .catch((error) => {
        // setBasketLoading(true);

        if (error.response.status === 401) {
          minusRefreshToken(id);
        }
      });
  };

  const onClickMinus = (id) => {
    minusRefreshToken(id);
  };

  const onClickDelete = (id) => {
    minusRefreshToken(id);
  };

  return (
    <>
      <HeaderProfile title_page="سبد خرید" />
      <div className="p-2 " style={{ fontSize: "12px", marginBottom: "150px" }}>
        <div
          className="bg-light p-1"
          style={{ height: "30px", fontSize: "12px" }}
        >
          <p>
            <span style={{ color: "#b400ae" }}>سبدخرید</span>
            <IoIosArrowBack />
            تعیین آدرس
            <IoIosArrowBack />
            پرداخت
          </p>
        </div>
        {basketLoading ? (
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ height: "50vh" }}
          >
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : basket.length > 0 ? (
          basket[0].products.length > 0 ? (
            <>
              <div className="my-2">
                <ListGroup>
                  {basket[0]?.products.map((item, index) => (
                    <OrderItem
                      key={index}
                      image1={item.product.image1}
                      image2={item.product.image2}
                      image3={item.product.image3}
                      image4={item.product.image4}
                      image5={item.product.image5}
                      shop={item.product.shop}
                      title={item.product.title}
                      price={numberWithCommas(item.product.price)}
                      quantity={item.quantity}
                      onClickMinus={() => onClickMinus(item.product.id)}
                      onClickDelete={() => onClickDelete(item.product.id)}
                      onClickPlus={() => onClickPlus(item.product.id)}
                    />
                  ))}
                </ListGroup>
              </div>
              <div className="px-3 py-2 shadow ">
                <p className="fw-bold m-0" style={{ fontSize: "15px" }}>
                  اطلاعات پرداخت
                </p>
                <p>(تمامی قیمت ها به ریال میباشد)</p>
                <div className="d-flex justify-content-between">
                  <p>
                    قیمت کالاها <span>({basket[0].product_count})</span>
                  </p>
                  {numberWithCommas(basket[0].total_price)} ریال
                </div>
                <hr
                  style={{ width: "93%", border: "1px dashed #292929" }}
                  className="mx-auto"
                />
                <div className="d-flex justify-content-between">
                  <p>قابل پرداخت</p>
                  <p style={{ fontSize: "20px fw-bold" }}>
                    {numberWithCommas(basket[0].total_price)} ریال
                  </p>
                </div>
                <div
                  className="d-flex p-3 my-1"
                  style={{ backgroundColor: "#fffde5" }}
                >
                  <span className="ps-1">
                    <MdOutlineInfo size={"1.3rem"} />
                  </span>
                  <p>هزینه ارسال بعد از انتخاب آدرس مشخص خواهد شد.</p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between px-3 py-2 align-items-center bg-white w-100 shadow-lg"
                style={{ position: "fixed", bottom: "70px", left: "0px" }}
              >
                <div>
                  <p className="m-0" style={{ fontSize: "16px" }}>
                    قابل پرداخت
                  </p>
                  <p className="m-0 fw-bold" style={{ fontSize: "16px" }}>
                    {" "}
                    {numberWithCommas(basket[0].total_price)} ریال
                  </p>
                </div>
                <button
                  className="btn btn-red "
                  style={{ padding: "10px 25px" }}
                  onClick={chekout}
                >
                  تعیین آدرس
                </button>
              </div>
            </>
          ) : (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ height: "60vh" }}
            >
              <p className="text-secondary m-0" style={{ fontSize: "17px" }}>
                سبد خرید شما خالی است!
              </p>
            </div>
          )
        ) : (
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ height: "50vh" }}
          >
            <p className="text-secondary m-0" style={{ fontSize: "17px" }}>
              سبد خرید شما خالی است!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersShop;
