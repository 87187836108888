import { useEffect, useState } from "react";
import { HeaderProfile, LoadingSkeleton } from "../../components";
import axios from "axios";
import { Link } from "react-router-dom";

const AllShop = () => {
  const [shops, setShops] = useState([]);
  const [loadingShops, setLoadingShops] = useState(true);

  useEffect(() => {
    shopsRefreshToken();
  }, []);

  const shopsRefreshToken = async () => {
    setLoadingShops(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShops();
      }
    } catch (error) {}
  };

  const getShops = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shop`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShops(false);

        setShops(response.data.results);
      })
      .catch((error) => {
        setLoadingShops(false);

        if (error.response.status === 401) {
          shopsRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="فروشگاه ها" />
      <div className="mx-2 my-4">
        <div className="row m-0 g-2">
          {loadingShops ? (
            <>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
              <div className="col-4">
                <LoadingSkeleton height="100px" width="100%" borderRadius={4} />
              </div>
            </>
          ) : (
            shops?.map((item, index) => (
              <div
                className="col-4 col-sm-3 col-lg-2 col-md-2 col-xl-1"
                key={index}
              >
                <Link
                  to={`/category/shop/${item.id}`}
                  style={{ textDecoration: "none", color: "#535252" }}
                >
                  <div className="card " style={{ height: "120px" }}>
                    <img
                      className="card-img-top"
                      src={item.image}
                      alt="Card image cap"
                      style={{ height: "90px" }}
                    />
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <p
                        className="card-title m-0"
                        style={{ fontSize: "13px" }}
                      >
                        {item.title.length >10 ? (item.title.substring(0,10)+ " ...") : (item.title) }
                        
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default AllShop;
