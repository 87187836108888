import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
    baseUrl: 'https://devapi.mykilan.ir/',
    prepareHeaders: async (headers, {getState}) => {
        const token = await localStorage.getItem("token_access")
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const refreshResult =await baseQuery(
        {
            url: 'authentication/token/refresh/',
            method: 'POST',
            body: {
                refresh: localStorage.getItem('token_refresh'),
            },
        },
        api,
        extraOptions
    );
    if (refreshResult.data) {
        await localStorage.setItem('token_access', refreshResult.data.access);
        result = await baseQuery(args, api, extraOptions);
    }

    return result;
};

export const BaseApi = createApi({
    reducerPath: "mykilan",
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
})

//if check to 401
// if (result.error && result.error.status === 401) {
//     const refreshResult =await baseQuery(
//         {
//             url: 'authentication/token/refresh/',
//             method: 'POST',
//             body: {
//                 refresh: localStorage.getItem('token_refresh'),
//             },
//         },
//         api,
//         extraOptions
//     );
//     if (refreshResult.data) {
//         await localStorage.setItem('token_access', refreshResult.data.access);
//         result = await baseQuery(args, api, extraOptions);
//     }
// }

//baseQuery in the base api
// baseQuery: fetchBaseQuery({
//     baseUrl: 'https://devapi.mykilan.ir/',
//     prepareHeaders: async (headers, {getState}) => {
//         const token = await localStorage.getItem("token_access")
//         if (token) {
//             headers.set('authorization', `Bearer ${token}`)
//         }
//         return headers
//     },
// }),