import { useEffect, useState } from "react";
import {
  HeaderProfile,
  ItemPersonalShope,
  LoadingSkeleton,
} from "../../components";
import axios from "axios";
import { numberWithCommas } from "../../utils/numberWithCommas";

const AllProduct = () => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    productsRefreshToken();
  }, []);

  const productsRefreshToken = async () => {
    setLoadingProducts(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productsShop();
      }
    } catch (error) {}
  };

  const productsShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shopproduct`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingProducts(false);

        setProducts(response.data.results);
        
      })
      .catch((error) => {
        setLoadingProducts(false);

        if (error.response.status === 401) {
          productsRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="محصولات" />
      <div className="mx-2 mt-2" style={{ marginBottom: "80px" }}>
        <div className="row m-0 g-1">
          {loadingProducts ? (
            <>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
            </>
          ) : (
            products?.map((item, index) => (
              <ItemPersonalShope
                key={index}
                image1={item.image1}
                image2={item.image2}
                image3={item.image3}
                image4={item.image4}
                image5={item.image5}
                title={item.title}
                price={numberWithCommas(item.price)}
                to={`/category/product/${item.id}`}
                rate={item.average_rating}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default AllProduct;
