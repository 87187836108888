import { Link } from "react-router-dom";

const HeaderProfile = ({ title_page }) => {
  return (
    <>
      <div className="bg-white mb-2 py-3 shadow-sm d-flex justify-content-center">
        <p className=" fw-bold m-0" style={{ fontSize: "18px" }}>
          {title_page}
        </p>
      </div>
    </>
  );
};

export default HeaderProfile;
