import axios from "axios";
import { useEffect, useState } from "react";
import { numberWithCommas } from "../../utils/numberWithCommas";
import HeaderProfile from "../../components/HeaderProfile/HeaderProfile";
import { useParams } from "react-router-dom";
import { findfullimage } from "../../utils/findfullimage";

const ItemMyByesconfirmed = () => {
  const { orderid } = useParams();
  const [loading, setLoading] = useState(true);
  const [orderItem, setOrderItem] = useState(null);

  useEffect(() => {
    ordersItemRefreshToken();
  }, []);

  ////

  const ordersItemRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        ordersItem();
      }
    } catch (error) {}
  };

  const ordersItem = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `rejectedorder/${orderid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        setOrderItem(response.data);
        
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          ordersItemRefreshToken();
        }
      });
  };

  return (
    <div style={{ marginBottom: "70px" }}>
      <HeaderProfile title_page={`محصولات سفارش`} />
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        orderItem.products.map((item, index) => (
          
            <div key={index} style={{ fontSize: "11px" }} className="mx-3">
              <div className="row row-cols-1 row-cols-md-3 g-2 my-2  ">
                <div className="col">
                  <div className="card mb-3">
                    <div className="row g-0">
                      <div className="col-4 d-flex align-items-center  ">
                      {findfullimage(
                          item.product.image1,
                           item.product.image2,
                          item.product.image3,
                          item.product.image4,
                          item.product.image5
                        ) !== false ? (
                          <img
                            src={"https://devapi.mykilan.ir" + findfullimage(
                              item.product.image1,
                              item.product.image2,
                              item.product.image3,
                              item.product.image4,
                              item.product.image5
                            )}
                            className="img-fluid rounded-1 m-1 "
                            alt="..."
                          />
                        ) : (
                          <img
                            src={window.location.origin + "/image/no_image.png"}
                            className="img-fluid rounded-1 m-1 "
                            alt="..."
                          />
                        )}
                      </div>
                      <div className="col-8">
                        <div className="card-body ">
                          <p
                            className="card-title"
                            style={{ fontSize: "17px" }}
                          >
                            {item.product.title}
                          </p>

                          <p className="card-text">{item.quantity} عدد</p>
                          <p className="card-text text-start">
                            {" "}
                            {numberWithCommas(item.product.price)} ریال
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        ))
      )}
    </div>
  );
};

export default ItemMyByesconfirmed;
