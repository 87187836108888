import { Link, useNavigate } from "react-router-dom";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { LoadingSkeleton, SearchBarButton } from "../../components";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdSearch } from "react-icons/io";

const SearchPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [shops, setShops] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingShops, setLoadingShops] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [sliderRef3] = useKeenSlider({
    rtl: true,
    mode: "free-snap",
    slides: {
      origin: "auto",
      perView: 2.3,
      spacing: 3,
    },
  });
  ///////////
  const shopsRefreshToken = async (sh) => {
    setLoadingShops(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShops(sh);
      }
    } catch (error) {}
  };

  const getShops = (sh) => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `servicejob/?title=${sh}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShops(false);
        setShops(response.data.results.reverse());
      })
      .catch((error) => {
        setLoadingShops(false);

        if (error.response.status === 401) {
          shopsRefreshToken();
        }
      });
  };
  ////
  const productsRefreshToken = async (pr) => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getProducts(pr);
      }
    } catch (error) {}
  };

  const getProducts = (pr) => {
    let token = localStorage.getItem("token_access");

    console.log("****************************************", searchValue);

    axios({
      method: "get",
      url: `servicejob/?title=${pr}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingProduct(false);
        setProducts(response.data.results.reverse());
      })
      .catch((error) => {
        setLoadingProduct(false);

        if (error.response.status === 401) {
          productsRefreshToken();
        }
      });
  };

  // useEffect(() => {
  //   onChangeSearch = async (e) => {
  //     if (e.target.value == "") {
  //       console.log("in if target");
  //       setProducts([]);
  //       setShops([]);
  //     } else {
  //       console.log("in in onchange ", e.target.value);
  //       const sts = e.target.value;
  //       setSearchValue(sts);
  //       console.log("search value----------", searchValue);
  //       productsRefreshToken();
  //       shopsRefreshToken();
  //     }
  //   };
  // }, [searchValue]);

  const onChangeSearch = async (e) => {
    if (e.target.value == "") {
      setSearchValue(e.target.value);
      setProducts([]);
      setShops([]);
    } else {
      setSearchValue(e.target.value);

      productsRefreshToken(e.target.value);
      shopsRefreshToken(e.target.value);
    }
  };

  return (
    <Wapper>
      <div className="elevation-on-bottom">
        <div className="w-100 py-2 px-1 border-search-input">
          <div className="row">
            <div className="col-10">
              <input
                placeholder="جستجوی خدمات"
                className="border-0 bg-transparent w-100 searchinput"
                style={{ height: "25px" }}
                onChange={onChangeSearch}
                value={searchValue}
              />
            </div>
            <div className="col-2">
              <IoMdSearch color="#9e9e9e" size={"1.5em"} />
            </div>
          </div>
        </div>
      </div>

      <div className="py-3 px-3" style={{ overflow: "hidden" }}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h1 className="text-font-slider mb-0">غرفه ها</h1>
        </div>
        {loadingShops ? (
          <div className="d-flex">
            <div className="m-2">
              <LoadingSkeleton height="150px" width="150px" borderRadius={4} />
            </div>

            <div className="m-2">
              <LoadingSkeleton height="150px" width="150px" borderRadius={4} />
            </div>
            <div className="m-2">
              <LoadingSkeleton height="150px" width="150px" borderRadius={4} />
            </div>
          </div>
        ) : (
          <div ref={sliderRef3} className="keen-slider">
            {shops?.map((item, index) => (
              <Link
                key={index}
                to={`/category/shop/${item.id}`}
                style={{ textDecoration: "none", color: "#535252" }}
                className="keen-slider__slide "
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="card-category">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt="test"
                      style={{ height: "90px", objectFit: "contain" }}
                    />
                  </div>
                  <p className="f-12 fw-bold mt-2">{item.title}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>

      <hr className="line" />
    </Wapper>
  );
};

export default SearchPage;

const Wapper = styled.section`
  margin-bottom: 79px;
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }

  .card-category {
    background-color: #f5f5f5;
    height: 120px;
    width: 120px;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
  }

  .line {
    height: 0.5rem;
    margin: 0;
    background-color: #ccc;
    border-top: none;
  }
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }
  .img-banner3 {
    width: 95%;
    height: 100px;
    object-fit: contain;
  }
  .searchinput:focus {
    outline: none;
  }
  .elevation-on-bottom {
    box-shadow: 0 2px 4px rgb(85 85 85 / 20%);
    padding: 5px 10px 5px 10px;
  }

  .text-input-search {
    border: none;
    width: 100%;
    background-color: transparent;
  }

  .text-input-search:focus-visible {
    border: none;
  }

  .border-search-input {
    position: relative;
    border-radius: 5px;
    background-color: #f5f5f5;
    height: 2.5rem;
  }
`;
