import { BaseApi } from './BaseApi'

const extendedBannerItemApi = BaseApi.injectEndpoints({
    tagTypes: ["BANNER_ITEM"],
    endpoints:  (builder)=>({
        getBannerItemShow: builder.query({
            query: () => 'banneritem/',
            providesTags: (result, error, id) => [{ type: 'BANNER_ITEM', id }],
        }),
    }),
    overrideExisting: false,
})

export const { useGetBannerItemShowQuery  } = extendedBannerItemApi