export function numberWithCommas(x) {
  x = x
    .toString()
    .replace(',', '')
    .replace(',', '')
    .replace(',', '')
    .replace(',', '')
    .replace(',', '')
    .replace(',', '');
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
