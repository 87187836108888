import Card1 from "../../assets/images/9b0ac699-e9a4-4f3b-b403-4a709b60a468.webp";
import styled from "styled-components";

const BannerCartOneItem=({image,title})=>{
    return(
        <Wapper>
            <div className="py-3">
                <div className="card-banner d-flex justify-content-center align-items-center">
                    <img src={image} alt={title} className="img-banner"/>
                </div>
            </div>
        </Wapper>
    )
}
export default BannerCartOneItem

const Wapper=styled.section`
  //banner
  .card-banner {
    width: 100%;
    height: 120px;
  }
  .img-banner {
    width: 95%;
    height: 120px;
    border-radius: 5px;
    object-fit: fill;
  }
  //banner
`