import styled from "styled-components";
import {useKeenSlider} from "keen-slider/react";

import I1 from "../../assets/images/9a204326-a864-466a-a094-db7aa99d6ef1.webp";
import I2 from "../../assets/images/9a33495f-93e0-4e3e-ab91-034828074157.webp";
import I3 from "../../assets/images/9a661dd0-35d6-4f94-9210-c499f40e06e8.webp";
import I4 from "../../assets/images/9af327d4-d73d-4956-b7bf-bab8cf53ff5b.webp";
import I5 from "../../assets/images/9a7a8029-75c0-4b7f-a5b5-23eaabf3ebc0.webp";

const SliderSuggestionCategory = ({children}) => {

    const [sliderRef2] = useKeenSlider({
        rtl: true,
        mode: "free-snap",
        slides: {
            origin: "auto",
            perView: 3.4,
            spacing: 2,
        },
        breakpoints: {
            '(max-width: 400px)': {
                slides: {
                    origin: "auto",
                    perView: 3.4,
                    spacing: 2,
                },
            },
            '(max-width: 330px)': {
                slides: {
                    origin: "auto",
                    perView: 2.4,
                    spacing: 2,
                },
            },
        },
    })
    return (
        <Wapper>
            <div className="mt-2 px-3 py-1">
                <div ref={sliderRef2} className="keen-slider">
                    {children}
                </div>
            </div>
        </Wapper>
    )
}

export default SliderSuggestionCategory

const Wapper = styled.section`
  //slider 2
  .card-size-img {
    width: 60px;
    height: 60px;
  }

  //slider2
`