import { MdOutlineInfo } from "react-icons/md";
import { HeaderProfile } from "../../components";

const Wallet = () => {
  return (
    <>
      <HeaderProfile title_page="کیف پول" />
      <div
        className="bg-withe shadow-lg p-3"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "550px",
        }}
      >
        <div className="p-2 text-secondary justify-content-center d-flex ">
          <p style={{ fontSize: "14px" }}>(تمامی قیمت ها به تومان و تا سقف 10 میلیون تومان است)</p>
        </div>

        <div style={{ border: "1px solid rgba(0, 0, 0, 0.175)" }}>
          <div className="d-flex justify-content-between p-3">
            <p className="m-0">موجودی کیف پول</p>
            <p className="m-0">0</p>
          </div>
          <hr className="m-0" />
          <div className="d-flex justify-content-between p-3">
            <p className="m-0">مقدار افزایش موجودی</p>
            <p className="m-0">0</p>
          </div>
        </div>
        <div
          className="d-flex p-3 my-3 border-2"
          style={{ backgroundColor: "#fffde5" }}
        >
          <span className="ps-1">
            <MdOutlineInfo size={"1.3rem"} />
          </span>
          <p style={{ fontSize: "12px" }}>
            برای افزایش موجود نیاز است ورود به آسان پرداخت و افزایش را زده و به
            کیف پول خود مراجعه کنید.
          </p>
        </div>
      </div>
      <div
        className="d-flex justify-content-around px-3 py-3 align-items-center bg-white w-100 shadow-lg"
        style={{ position: "fixed", bottom: "70px", left: "0px" }}
      >
        <button
          className="btn btn-outline-red "
          style={{ padding: "10px 25px" }}
        >
          انصراف
        </button>
        <button className="btn btn-red " style={{ padding: "10px 25px" }}>
          افزایش موجودی کیف پول
        </button>
      </div>
    </>
  );
};

export default Wallet;
