
import {useKeenSlider} from "keen-slider/react";
import styled from "styled-components";
import {CartProduct} from "../index";

const SliderCartProducts=({children,title})=>{
    const [sliderRef3] = useKeenSlider({
        rtl: true,
        mode: "free-snap",
        slides: {
            origin: "auto",
            perView: 2.2,
            spacing: 5,
        },
    })

    return(
        <Wapper>
            <div className="py-3 px-3">
                <h1 className="text-font-slider mb-3">{title}</h1>
                <div ref={sliderRef3} className="keen-slider">
                    {children}
                </div>
            </div>
        </Wapper>
    )
}

export default SliderCartProducts

const Wapper=styled.section`
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }
`