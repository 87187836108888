import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { defultimage } from "../../utils/defultimage";
import { FaMinus, FaPlus, FaRegStar } from "react-icons/fa";
import { FaRegTrashCan, FaStar } from "react-icons/fa6";
import { Rating } from "react-simple-star-rating";

import { LoadingSkeleton } from "../../components";
import { toast } from "react-toastify";
import { BsShop } from "react-icons/bs";
import { useKeenSlider } from "keen-slider/react";
import "./style.css";

const ProductPage = () => {
  const [basket, setBasket] = useState(null);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [foundState, setFoundState] = useState(false);
  const [quantity, setQuantity] = useState("");
  const { productid } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    rtl: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    productRefreshToken();
    getBasketRefreshToken();
  }, []);

  const handleRating = (rate) => {
    ratingRefreshToken(rate);
  };

  const ratingRefreshToken = async (rate) => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        postRate(rate);
      }
    } catch (error) {}
  };

  const postRate = (rate) => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "post",
      url: `shopproductrating/`,
      data: {
        rate: rate,
        product: productid,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success("امتیاز شما با موفقیت ثبت شد!");
        productRefreshToken();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          }
        }

        if (error.response.status === 401) {
          ratingRefreshToken();
        }
      });
  };

  const productRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productShop();
      }
    } catch (error) {}
  };

  const productShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `servicejobsample/${productid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);

        setProduct(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          productRefreshToken();
        }
      });
  };
  /////
  const addProductToBasketRefreshToken = async () => {
    // setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        putAddProductToBasket();
      }
    } catch (error) {}
  };

  const putAddProductToBasket = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "post",
      url: `shoppingcart/`,
      data: {
        product_id: parseInt(productid),
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setLoading(false);
        // setBtnLoading(false);

        getBasketRefreshToken();

        // toast.success("محصول شما با موفقیت به سبد خرید اضافه شد.");
      })
      .catch((error) => {
        // setLoading(false);

        if (error.response.status === 401) {
          addProductToBasketRefreshToken();
        }
      });
  };

  const getBasketRefreshToken = async () => {
    // setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getBasket();
      }
    } catch (error) {}
  };

  const getBasket = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shoppingcart/`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setLoading(false);
        if (response.data.length > 0) {
          const found = response.data[0].products.find(
            (element) => element.product.id === parseInt(productid)
          );

          if (found !== undefined) {
            setFoundState(true);
            setQuantity(found.quantity);
          } else {
            setFoundState(false);
          }
        }

        setBtnLoading(false);
      })
      .catch((error) => {
        // setLoading(false);

        if (error.response.status === 401) {
          getBasketRefreshToken();
        }
        setBtnLoading(false);

      });
  };

  /////
  const plusRefreshToken = async () => {
    // setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        plus();
      }
    } catch (error) {}
  };

  const plus = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "post",
      url: `shoppingcart/`,
      data: {
        product_id: parseInt(productid),
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setBasketLoading(true);
        // setBtnLoading(false);

        getBasketRefreshToken();
      })
      .catch((error) => {
        // setBasketLoading(true);

        if (error.response.status === 401) {
          plusRefreshToken();
        }
      });
  };
  ////
  const onClickPlus = () => {
    setBtnLoading(true);
    plusRefreshToken();
  };
  ////
  const minusRefreshToken = async () => {
    // setBasketLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        minus();
      }
    } catch (error) {}
  };

  const minus = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `shoppingcart/removeproduct/`,
      data: {
        product_id: parseInt(productid),
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // setBasketLoading(true);
        // setBtnLoading(false);

        getBasketRefreshToken();
      })
      .catch((error) => {
        // setBasketLoading(true);

        if (error.response.status === 401) {
          minusRefreshToken();
        }
      });
  };
  ////
  const onClickMinus = () => {
    setBtnLoading(true);
    minusRefreshToken();
  };
  ////
  const onClickDelete = () => {
    setBtnLoading(true);
    minusRefreshToken();
  };
  /////

  const addProductToBasket = () => {
    setBtnLoading(true);
    addProductToBasketRefreshToken();
  };
  /////

  /////
  return (
    <div>
      {loading ? (
        <>
          <div className="mt-5">
            <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
          </div>

          <div className="mx-3  mt-5 ">
            <div className="d-flex justify-content-between mb-3">
              <LoadingSkeleton height="10px" width="200px" borderRadius={4} />
              <div>
                <LoadingSkeleton height="10px" width="30px" borderRadius={4} />
              </div>
            </div>
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="100%" borderRadius={4} />
            <LoadingSkeleton height="10px" width="50%" borderRadius={4} />
          </div>

          <div className="mt-5 ms-3 float-start">
            <LoadingSkeleton height="40px" width="100px" borderRadius={4} />
          </div>
        </>
      ) : (
        <div style={{ marginBottom: "170px" }}>
          {defultimage(
            product.image1,
            product.image2,
            product.image3,
            product.image4,
            product.image5
          ) ? (
            <div style={{ height: "250px" }}>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  <div
                    className="keen-slider__slide  "
                    style={{ height: "250px" }}
                  >
                    <img
                      src={window.location.origin + `/image/no_image.png`}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: "250px" }}>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  {product.image1 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image1}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image2 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image2}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image3 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image3}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image4 !== null ? (
                    <div
                      className="keen-slider__slide  "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image4}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {product.image5 !== null ? (
                    <div
                      className="keen-slider__slide "
                      style={{ height: "250px" }}
                    >
                      <img
                        src={product.image5}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
                {loaded && instanceRef.current && (
                  <div className="dotsproducts">
                    {[
                      ...Array(
                        instanceRef.current.track.details.slides.length
                      ).keys(),
                    ].map((idx) => {
                      return (
                        <button
                          key={idx}
                          onClick={() => {
                            instanceRef.current?.moveToIdx(idx);
                          }}
                          className={
                            "dotproducts" +
                            (currentSlide === idx ? " activee" : "")
                          }
                        ></button>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="mx-3  mt-5">
            <div className="d-flex justify-content-between">
              {" "}
              <h5 className="mb-4">{product.title}</h5>
              <div className="ms-1 d-flex ">
                <p>{product.average_rating}</p>
                <FaStar
                  className="me-2"
                  color="gold"
                  style={{ marginTop: "2px" }}
                />
              </div>
            </div>
            <p>
              <BsShop /> {product.title}
            </p>
            <p className="text-muted" style={{ textAlign: "justify" }}>
              {product.description}
            </p>
          </div>

          <div className="mt-5 ms-3 float-start">
            <button
              className="btn btn-outline-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#reportcanvasBottom"
              aria-controls="reportcanvasBottom"
            >
              امتیاز بده!
            </button>
          </div>


        </div>
      )}

      <div
        className="offcanvas offcanvas-bottom h-25"
        id="reportcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title "
            style={{ color: "#b400ae" }}
            id="offcanvasBottomLabel"
          >
            به این محصول چند امتیاز میدی؟!{" "}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body small align-items-center justify-content-center d-flex">
          <Rating onClick={handleRating} />
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
