import ListGroup from "react-bootstrap/ListGroup";
import { GrLocation } from "react-icons/gr";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { FaSignsPost } from "react-icons/fa6";
import { IoHomeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

const ItemAddress = ({
  title,
  status,
  address,
  number,
  post_code,
  unit_code,
  plak,
  to,
  edit,
}) => {
  return (
    <div className="mx-2 my-1">
      <ListGroup.Item>
        <div className=" px-2 py-3" style={{ overflow: "hidden" }}>
          <div style={{ fontSize: "13px" }} className="">
            <div className="">
              <p>
                <span className="h6 ">
                  {title === null ? "بدون عنوان" : title}
                </span>
                {status && (
                  <span className="text-danger"> (خارج از محدوده)</span>
                )}
              </p>
            </div>

          
            <div className="row g-1">

            <div className="col-12 text-secondary">
              <span className="text-dark">
                <GrLocation />
              </span>{" "}
              {address.length > 35
                ? address.substring(0, 35) + " ..."
                : address}
            </div>


              {plak || unit_code ? (
                <div className="col-6 text-secondary ">
                  <span className="text-dark">
                    <IoHomeOutline />
                  </span>{" "}
                  {plak !== null && <span>پلاک {plak}</span>}
                  {unit_code !== null && <span>واحد {unit_code}</span>}
                </div>
              ) : null}

              <div className="col-6 text-secondary">
                <span className="text-dark">
                  <IoPhonePortraitOutline />
                </span>{" "}
                {number}
              </div>

              {post_code !== null ? (
                <div className="col-6 text-secondary">
                  <span className=" text-dark">
                    <FaSignsPost />
                  </span>{" "}
                  {post_code}
                </div>
              ) : null}
            </div>
            {edit ? (
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <Link
                  to={to}
                  className="text-decoration-none costomize-color"
                  style={{ color: "#b400ae" }}
                >
                  ویرایش{" "}
                  <span>
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </ListGroup.Item>
    </div>
  );
};

export default ItemAddress;
