import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./BTcustome.css";
import { useState, useEffect } from "react";
import { ListGroup, FormControl } from "react-bootstrap";
import axios from "axios";
import { IoCloudUploadOutline } from "react-icons/io5";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Progress } from "../../components";
const MyShopReg = () => {
  const [pageNumber, setPageNumber] = useState("1");
  const [title, setTitle] = useState(null);
  const [ownerName, setOwnerName] = useState(null);
  const [address, setAddress] = useState(null);
  const [tradeCode, setTradeCode] = useState(null);
  const [image, setImage] = useState(null);
  const [shippingFee, setshippingFee] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [startTiming, setStartTiming] = useState("");
  const [endTiming, setEndTiming] = useState("");

  const [selectedImage, setSelectedImage] = useState(
    window.location.origin + "/image/no_image.png"
  );
  const [key, setKey] = useState(null);
  const [regShop, setRegShop] = useState(null);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [KeyChild, setKeyChild] = useState("");
  const [loading, setLoading] = useState(true);
  const [shop, setShop] = useState(null);
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    categoryRefreshToken();
    getShopRefreshToken();
  }, [pageNumber]);

  const categoryRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getCategory();
      }
    } catch (error) {}
  };

  const getCategory = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `/servicejobcategory/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);

        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          categoryRefreshToken();
        }
      });
  };
  //////
  const postCategoryRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        postCategory();
      }
    } catch (error) {}
  };

  const postCategory = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "post",
      url: `servicejob/`,
      data: {
        serviceCategory: KeyChild,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setPageNumber("2");
        setBtnLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          postCategoryRefreshToken();
        }
        setBtnLoading(false);
      });
  };

  //////

  const putInfoRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        putInfo();
      }
    } catch (error) {}
  };

  const putInfo = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();

    form.append("title", title);
    form.append("ownerName", ownerName);
    form.append("tradeCode", tradeCode);
    form.append("address", address);
    form.append("phoneNumber", phoneNumber);
    form.append("shippingFee", shippingFee);
    form.append("image", image);

    axios({
      method: "put",
      url: `servicejob/servicejobinformation/`,
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setPageNumber("3");
        setBtnLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          }
          if (error.response.data.shippingFee) {
            toast.error(error.response.data.shippingFee[0]);
          }
        }
        if (error.response.status === 401) {
          putInfoRefreshToken();
        }
        setBtnLoading(false);
      });
  };

  //////

  const putWorkingTimeRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        putWorkingTime();
      }
    } catch (error) {}
  };

  const putWorkingTime = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "put",
      url: `servicejob/servicejobworktime/`,

      data: {
        workingDaysStart: parseInt(startDay),
        workingDaysEnd: parseInt(endDay),
        workingHoursStart: startTiming+":00",
        workingHoursEnd: endTiming+":00",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate("/profile");
        toast.success("فروشگاه با موفقیت ایجاد شد!");
        setBtnLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.workingHoursEnd) {
            toast.error("ساعت وارد شده درست نیست!");
          }
          if (error.response.data.workingHoursStart) {
            toast.error("ساعت وارد شده درست نیست!");
          }
        }
        if (error.response.status === 401) {
          putWorkingTimeRefreshToken();
        }
        setBtnLoading(false);
      });
  };

  ////shop
  const getShopRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShop();
      }
    } catch (error) {}
  };

  const getShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `userservicejob/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.length !== 0) {
          setPageNumber(JSON.stringify(response.data[0].registerStatus));
        }
      })
      .catch((error) => {
        getShopRefreshToken();
      });
  };

  ///////

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleShopCategory = (ctid, ClickNextBut) => {
    if (ctid && ClickNextBut == "generalItem") {
      setKey("generalItem");
      // setRegShop({...regShop,["shopCategory"]:ctid})
      setRegShop((prv) => ({ ...prv, shopCategory: ctid }));
    }
  };

  const onChangeImage = (e) => {
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImage(e.target.files[0]);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeOwnerName = (e) => {
    setOwnerName(e.target.value);
  };

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const onChangeshippingFee = (e) => {
    setshippingFee(e.target.value);
  };

  const onChangeTradeCode = (e) => {
    setTradeCode(e.target.value);
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onChangeEndDay = (e) => {
    setEndDay(e.target.value);
  };

  const onChangeStartDay = (e) => {
    setStartDay(e.target.value);
  };

  const onChangeStartTiming = (e) => {
    setStartTiming(e.target.value);
  };

  const onChangeEndTiming = (e) => {
    setEndTiming(e.target.value);
  };

  const onClickButton1 = () => {
    setBtnLoading(true);
    if (KeyChild !== null && KeyChild !== "") {
      postCategoryRefreshToken();
    } else {
      setBtnLoading(false);
      toast.error("دسته بندی فروشگاه را انتخاب کنید!");
    }
  };

  const onClickButton3 = () => {
    setBtnLoading(true);
    if (
      title !== null &&
      title !== "" &&
      address !== null &&
      address !== "" &&
      ownerName !== null &&
      ownerName !== "" &&
      phoneNumber !== null &&
      phoneNumber !== "" &&
      shippingFee !== null &&
      shippingFee !== "" &&
      tradeCode !== null &&
      tradeCode !== "" &&
      image !== null &&
      image !== ""
    ) {
      putInfoRefreshToken();
    } else {
      toast.error("همه فیلدها باید پر شود!");
      setBtnLoading(false);
    }
  };

  const onChangeCreateMyShop = () => {
    setBtnLoading(true);
    if (
      startDay !== null &&
      startDay !== "0" &&
      endDay !== null &&
      endDay !== "0" &&
      startTiming !== null &&
      startTiming !== "" &&
      endTiming !== null &&
      endTiming !== ""
    ) {
      putWorkingTimeRefreshToken();
    } else {
      toast.error("همه فیلدها باید پر شود!");
      setBtnLoading(false);
    }
  };
  return (
    <>
      <Form style={{ padding: "10px", fontSize: "15px", marginBottom: "20px" }}>
        {pageNumber === "1" ? (
          <>
            <Progress pageNumber="1" />
            <div style={{ padding: "20px" }}>
              <FormControl
                type="text"
                placeholder="جستجو"
                className="mb-3"
                onChange={handleSearch}
              />
              {loading ? (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ height: "40vh" }}
                >
                  <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : shop !== undefined ? (
                <ListGroup>
                  {filteredItems.map((item) => (
                    <ListGroup.Item
                      style={{ cursor: "pointer" }}
                      className={
                        "d-flex justify-content-between align-items-center"
                      }
                      onClick={() => {
                        setKeyChild(item.id);
                      }}
                      key={item.id}
                    >
                      {item.title}{" "}
                      {item.id === KeyChild ? (
                        <FaCheck style={{ color: "green", fontSize: "20px" }} />
                      ) : null}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : null}
            </div>

            <br />
            <div className="d-flex justify-content-center">
              {btnLoading ? (
                <button class="btn btn-primary w-25" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  className="btn btn-primary w-25"
                  onClick={onClickButton1}
                  type="button"
                >
                  بعدی{" "}
                </button>
              )}
            </div>
          </>
        ) : pageNumber === "2" ? (
          <>
            <Progress pageNumber="2" />

            <Form.Group className="mb-3 mt-3">
              <Form.Label>
                عنوان غرفه<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={onChangeTitle}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                نام مالک<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={ownerName}
                onChange={onChangeOwnerName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                کد صنفی<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={tradeCode}
                onChange={onChangeTradeCode}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                آدرس فروشگاه<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={onChangeAddress}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                شماره همراه<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={onChangePhoneNumber}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                هزینه ارسال کالا <span className="text-danger">*</span>(ریال)
              </Form.Label>
              <Form.Control
                type="text"
                value={shippingFee}
                onChange={onChangeshippingFee}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                تصویر فروشگاه<span className="text-danger">*</span>
              </Form.Label>
              <br />
              <br />

              <label
                for="shopImage-upload"
                class="custom-file-upload "
                style={{ width: "160px" }}
              >
                انتخاب تصویر
                <IoCloudUploadOutline
                  size="20px"
                  style={{ marginRight: "30px" }}
                />
              </label>

              <input
                id="shopImage-upload"
                type="file"
                accept="image/*"
                onChange={onChangeImage}
                className="m-0"
              />
              <br />
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                />
              )}
            </Form.Group>

            <div className="d-flex justify-content-center">
              {btnLoading ? (
                <button class="btn btn-primary w-25" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  className="btn btn-primary w-25"
                  onClick={onClickButton3}
                  type="button"
                >
                  بعدی{" "}
                </button>
              )}
            </div>
            <br />
            <br />
            <br />
          </>
        ) : (
          <>
            <Progress pageNumber="3" />

            <Form.Group className="mb-3 mt-3">
              <Form.Label>
                روز شروع فعالیت<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                aria-label=""
                value={startDay}
                onChange={onChangeStartDay}
              >
                <option value="0">روز شروع فعالیت را انتخاب کنید</option>
                <option value="1">شنبه </option>
                <option value="2">یکشنبه</option>
                <option value="3">دوشنبه</option>
                <option value="4">سه شنبه</option>
                <option value="5">چهارشنبه</option>
                <option value="6">پنجشنبه</option>
                <option value="7">جمعه</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                روز پایان فعالیت<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                aria-label=""
                value={endDay}
                onChange={onChangeEndDay}
              >
                <option value="0">روز پایان فعالیت را انتخاب کنید</option>
                <option value="1">شنبه </option>
                <option value="2">یکشنبه</option>
                <option value="3">دوشنبه</option>
                <option value="4">سه شنبه</option>
                <option value="5">چهارشنبه</option>
                <option value="6">پنجشنبه</option>
                <option value="7">جمعه</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                ساعت شروع فعالیت<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={startTiming}
                onChange={onChangeStartTiming}
                placeholder=" ساعت"
                maxLength={2}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                ساعت پایان فعالیت<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={endTiming}
                onChange={onChangeEndTiming}
                placeholder=" ساعت"
                maxLength={2}
              />
            </Form.Group>

            <br />
            <br />

            <div className="d-flex justify-content-evenly ">
              {btnLoading ? (
                <button class="btn btn-primary w-50" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  className="btn btn-primary w-50"
                  onClick={onChangeCreateMyShop}
                  type="button"
                >
                  ایجاد غرفه
                </button>
              )}
            </div>
          </>
        )}
      </Form>
      <NotificationContainer />
    </>
  );
};

export default MyShopReg;
