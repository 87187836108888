import { FaPhoneFlip } from "react-icons/fa6";
import { datachangemoment } from "../../utils/datachangemoment";
import { MdLocationOn } from "react-icons/md";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { Link } from "react-router-dom";

const OrderMyByes = ({ arrayorder, page }) => {
  return (
    <>
      {arrayorder.map((item, index) => (
        <Link
          key={index}
          to={`/profile/orders/${page}/${item.id}`}
          style={{ textDecoration: "none", color: "#292929" }}
        >
          <div className="my-3">
            <div
              className=" mx-3 p-2"
              key={index}
              style={{
                border: "1px solid #ccc",
                fontSize: "12px",
                borderRadius: "5px",
              }}
            >
              <div className="row m-0 w-100">
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">سفارش : {item.id}</p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3 ">
                  {" "}
                  <p className="m-1">{datachangemoment(item.created_at)}</p>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    <FaPhoneFlip /> {item.user}
                  </p>
                </div>{" "}
                <div className="col-6 col-sm-6 col-lg-3 col-md-3 col-xl-3">
                  <p className="m-1">
                    {numberWithCommas(item.total_price)} ریال
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6">
                  <p className="m-1">
                    <MdLocationOn size="15px" />
                    {item.address.address}
                  </p>
                </div>
                {item.reason_for_rejection !== null && (
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6">
                    <p className="m-1">
                      دلیل لغو : {item.reason_for_rejection}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default OrderMyByes;

{
  /* {item.products.map((productitem, productindex) => (
              <div
                key={productindex}
                
                className=" px-3"
                style={{ fontSize: "11px" }}
              >
                <div className="row row-cols-1 row-cols-md-3 g-2 my-2 ">
                  <div className="col">
                    <div className="card mb-3">
                      <div className="row g-0">
                        <div className="col-4 d-flex align-items-center ">
                          <img
                            src={productitem.image1}
                            className="img-fluid rounded-1 "
                            alt="..."
                          />
                        </div>
                        <div className="col-8">
                          <div className="card-body ">
                            <p
                              className="card-title"
                              style={{ fontSize: "17px" }}
                            >
                              {productitem.title}
                            </p>

                            <p className="card-text">۱ عدد</p>
                            <p className="card-text text-start">
                              {" "}
                              {productitem.price} ریال
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-body"></div>
              </div>
            ))} */
}
