import styled from "styled-components";

const CartProduct=({src,title,discount,price})=>{
    return(
        <Wapper>
            <div
                className="card h-100 d-flex justify-content-center align-items-center overflow-hidden">
                <div className="card-image-slider w-100 h-100">
                    <img src={src} className="card-img-top image-size-cart height-130" alt="test"/>
                </div>
                <div className="card-body border-top-custom w-100 px-1 py-2 height-60 overflow-hidden">
                    <p className="card-text f-12 text-end">{title}</p>
                </div>
                <div className="card-footer bg-transparent border-0 px-1 py-3 w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            {/*<div className="discount d-flex justify-content-center align-items-center">*/}
                            {/*    80%*/}
                            {/*</div>*/}
                        </div>
                        <div
                            className="col-7 d-flex justify-content-center align-items-center flex-column">
                            {/*<s className="m-0 f-12 text-secondary">{discount}</s>*/}
                            <p className="m-0 f-12">{price} تومان</p>
                        </div>
                    </div>
                </div>
            </div>
        </Wapper>
    )
}

export default CartProduct

const Wapper=styled.section`
  .card-image-slider {
    padding: 0.25rem;
    width: 8.25rem;
    height: 8.25rem;
    min-width: 8.25rem;
    min-height: 8.25rem;
  }
  
  .image-size-cart{
    object-fit: contain;
  }
  
  .height-130{
    height: 130px;
  }
  
  .height-60{
    height: 60px;
  }

  .border-top-custom {
    border-top: 1px solid #ccc;
  }

  .discount {
    border: 1px solid #b400ae;
    border-radius: 5px;
    color: #b400ae;
    height: 1.5rem;
    width: 1.5rem;
    font-size: .75rem;
    letter-spacing: 0;
    line-height: 1rem;
  }
`