import { useNavigate, useParams } from "react-router-dom";
import { AdminItemPersonalShope, LoadingSkeleton } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { FaRegClock } from "react-icons/fa";
import { LuCalendarDays } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { BsCartCheckFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { findfullimage } from "../../utils/findfullimage";

const AdminPersonalShop = () => {
  const { shopid } = useParams();
  const [shop, setShop] = useState(null);
  const [product, setProduct] = useState([]);
  const [loadingShop, setLoadingShop] = useState(true);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    shopRefreshToken();
    productRefreshToken();
  }, []);

  const createnewproduct = () => {
    navigate(`/profile/myshop/${shopid}/createmyproduct`);
  };

  const shopRefreshToken = async () => {
    setLoadingShop(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShop();
      }
    } catch (error) {}
  };

  const getShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `servicejob/${shopid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShop(false);
        setShop(response.data);
      })
      .catch((error) => {
        setLoadingShop(false);
        if (error.response.status === 401) {
          shopRefreshToken();
        }
      });
  };

  const productRefreshToken = async () => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productShop();
      }
    } catch (error) {}
  };

  const productShop = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `returnsamplebyservicejob/${shopid}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {

        setLoadingProduct(false);
        setProduct(response.data);
      })
      .catch((error) => {
        setLoadingProduct(false);

        if (error.response.status === 401) {
          productRefreshToken();
        }
      });
  };

  const onClickTrash = (id) => {
    trashRefreshToken(id);
  };

  const trashRefreshToken = async (id) => {
    setLoadingProduct(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getTrash(id);
      }
    } catch (error) {}
  };

  const getTrash = (id) => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "delete",
      url: `servicejobsample/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.error("محصول با موفقیت پاک شد!");
        productRefreshToken();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          trashRefreshToken(id);
        }
      });
  };

  const onClickAdminOrder = () => {
    navigate(`/profile/myshop/${shopid}/myordershop`);
  };

  return (
    <div style={{ marginBottom: "75px" }}>
      {loadingShop ? (
        <div className="m-2" style={{ marginBottom: "100px" }}>
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div>
              <LoadingSkeleton height="10px" width="80px" borderRadius={4} />
            </div>
            <div>
              <LoadingSkeleton height="70px" width="70px" circle={true} />
            </div>
          </div>

          <div className="m-2 px-2  py-4">
            <LoadingSkeleton height="10px" width="150px" borderRadius={4} />
            <LoadingSkeleton height="10px" width="170px" borderRadius={4} />
          </div>
          <div>
            <LoadingSkeleton height="50px" width="100%" borderRadius={5} />
          </div>
        </div>
      ) : (
        <div className="m-2" style={{ marginBottom: "100px" }}>
          <div className="p-2 d-flex justify-content-between">
            <p className=" m-0 d-flex align-items-center justify-content-center">
              {shop.title}
            </p>

            <img
              src={shop.image}
              alt=""
              className="rounded-circle shadow"
              style={{ width: "70px", height: "70px", objectFit: "cover" }}
            />
          </div>

          <div className="m-2 px-2  py-4">
            <p style={{ fontSize: "12px" }}>
              <LuCalendarDays className="ms-2 text-secondary" size="17px" />
              روزهای کاری :{" "}
              {shop.workingDaysStart === shop.workingDaysEnd ? (
                "هر روز"
              ) : (
                <>
                  {shop.workingDaysStart === 1
                    ? "شنبه"
                    : shop.workingDaysStart === 2
                    ? "یکشنبه"
                    : shop.workingDaysStart === 3
                    ? "دوشنبه"
                    : shop.workingDaysStart === 4
                    ? "سه شنبه"
                    : shop.workingDaysStart === 5
                    ? "چهارشنبه"
                    : shop.workingDaysStart === 6
                    ? "پنج شنبه"
                    : "جمعه"}{" "}
                  تا{" "}
                  {shop.workingDaysEnd === 1
                    ? "شنبه"
                    : shop.workingDaysEnd === 2
                    ? "یکشنبه"
                    : shop.workingDaysEnd === 3
                    ? "دوشنبه"
                    : shop.workingDaysEnd === 4
                    ? "سه شنبه"
                    : shop.workingDaysEnd === 5
                    ? "چهارشنبه"
                    : shop.workingDaysEnd === 6
                    ? "پنج شنبه"
                    : "جمعه"}
                </>
              )}
            </p>

            <p style={{ fontSize: "12px" }}>
              <FaRegClock className="ms-2 text-secondary" size="17px" />
              ساعت کاری : {shop.workingHoursStart.substring(0, 5)} تا{" "}
              {shop.workingHoursEnd.substring(0, 5)}
            </p>
          </div>
          <div className="d-flex justify-content-around">
            {/* <button
              className="btn btn-outline-secondary w-50 mx-1"
              onClick={onClickAdminOrder}
            >
              <BsCartCheckFill size="20px" /> سفارشات فروشگاه
            </button> */}
            <button
              className="btn btn-outline-danger w-50 mx-1"
              onClick={createnewproduct}
            >
              <FaPlus /> افزودن نمونه کار
            </button>
          </div>
        </div>
      )}
      <div className="mt-5">
        <div className="row m-0 g-1">
          {loadingProduct ? (
            <>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
            </>
          ) : product.length === 0 ? (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ height: "40vh" }}
            >
              <h4 className="text-secondary">شمار هنوز نمونه کاری اضافه نکرده اید</h4>
            </div>
          ) : (
            product?.map((item, index) => (
              <AdminItemPersonalShope
                key={index}
                image1={item.image1}
                image2={item.image2}
                image3={item.image3}
                image4={item.image4}
                image5={item.image5}
                title={item.title}
                // price={numberWithCommas(item.price)}
                // rate={item.average_rating}
                description={item.description}
                editto={`/profile/myshop/${shopid}/editmyproduct/${item.id}`}
                onClickTrash={() => onClickTrash(item.id)}
                to={`/profile/myshop/${shopid}/myproduct/${item.id}`}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPersonalShop;
