import ListGroup from "react-bootstrap/ListGroup";
import { FiShield } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { BsShop } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { useState } from "react";
import { findfullimage } from "../../utils/findfullimage";

const OrderItem = ({
  image1,
  image2,
  image3,
  image4,
  image5,
  title,
  price,
  shop,
  quantity,
  onClickMinus,
  onClickPlus,
  onClickDelete,
}) => {
  return (
    <>
      <div className="my-1 mx-2">
        <ListGroup.Item>
          <div className="row px-2 py-1  ">
            <div className="col-4">
              {findfullimage(image1, image2, image3, image4, image5) !==
              false ? (
                <img
                  src={"https://devapi.mykilan.ir" + findfullimage(image1, image2, image3, image4, image5)}
                  alt="test"
                  style={{ width: "70px", height: "70px", objectFit: "fill" }}
                />
              ) : (
                <img
                  src={window.location.origin + "/image/no_image.png"}
                  alt="test"
                  style={{ width: "70px", height: "70px", objectFit: "fill" }}
                />
              )}
            </div>
            <div className="col-8 mt-3 px-4 ">
              <p>{title}</p>

              <div className="text-secondary">
                <BsShop />
                <span className="mx-2"> {shop}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between p-0">
              <p className="pt-4" style={{ marginRight: "35px" }}>
                {price} ریال
              </p>

              <div
                className="d-flex justify-content-between align-items-center p-3 rounded-5 mt-2 "
                style={{
                  border: "1px solid #cfd0d1",
                  width: "120px",
                  height: "2.5rem",
                }}
              >
                <button
                  className="border-0 bg-white text-secondary"
                  onClick={onClickPlus}
                >
                  <FaPlus />
                </button>
                <p className="text-dark m-0">{quantity}</p>
                {quantity === parseInt(1) ? (
                  <button
                    className="border-0 bg-white text-secondary"
                    onClick={onClickDelete}
                  >
                    <FaRegTrashCan />
                  </button>
                ) : (
                  <button
                    className="border-0 bg-white text-secondary"
                    onClick={onClickMinus}
                  >
                    <FaMinus />
                  </button>
                )}
              </div>
            </div>
          </div>
        </ListGroup.Item>
      </div>
    </>
  );
};

export default OrderItem;
