import { useEffect, useState } from "react";
import { ItemAddress } from "../../components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddressCheckBox = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAddress, setSelectAddress] = useState();
  const navigate = useNavigate();

  // const { id } = useParams();

  useEffect(() => {
    addressRefreshToken();
  }, []);

  const onChangeCheckBox = (e) => {
    setSelectAddress(e);
    // console.log(e)
  };

  const addressRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getAddress();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAddress = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shopuseraddress/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAddresses(response.data.reverse());

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          addressRefreshToken();
        }
      });
  };

  const navigateToAddress = () => {
    navigate("/profile/address/new-address", { state: "checkboxaddress" });
  };
  const navigateaccept = () => {
    if (selectAddress === undefined) {
      toast.error("لطفا آدرس مورد نظر را انتخاب کنید!");
    } else {
      navigate("/orders/checkout", { state: selectAddress });
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : addresses.length === 0 ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "50vh" }}
        >
          <h4 className="text-secondary">آدرسی ثبت نکرده اید!</h4>
        </div>
      ) : (
        <div className="mx-3 mt-3" style={{ marginBottom: "150px" }}>
          {addresses?.map((item, index) => (
            <div
            key={index}
              className="form-check ps-5 pt-3 mb-2"
              style={{
                border: "1px solid rgba(0,0,0,0.175)",
                borderRadius: "7px",
              }}
            >
              <input
                className="form-check-input  "
                style={{
                  padding: "5px",
                  border: "1px solid rgba(0, 0, 0, 0.175)",
                }}
                type="radio"
                name="flexRadioDefault"
                id={`flexRadioDefault1${index}`}
                onChange={() =>
                  onChangeCheckBox({ id: item.id, address: item.address })
                }
              />
              <label
                className="form-check-label p-2 w-100 h-100"
                htmlFor={`flexRadioDefault1${index}`}
              >
                <ItemAddress
                  key={index}
                  title={item.title}
                  status={false}
                  address={item.address}
                  number={item.delivery_receiverPhone}
                  post_code={item.postal_code}
                  unit_code={item.unit_number}
                  plak={item.building_number}
                  to={`/profile/address/edit/${item.id}`}
                  edit={false}
                />
              </label>
            </div>
          ))}
        </div>
      )}
      <div
        className=" px-3 py-2 bg-white w-100 shadow-lg justify-content-between d-flex"
        style={{
          position: "fixed",
          bottom: "70px",
          height: "4rem",
        }}
      >
        <button
          className=" btn btn-primary w-50 ms-1 "
          onClick={navigateToAddress}
        >
          ایجاد آدرس
        </button>
        <button className=" btn btn-primary w-50 me-1" onClick={navigateaccept}>
          تایید
        </button>
      </div>
    </>
  );
};

export default AddressCheckBox;
