import Card3 from "../../assets/images/9ac06071-970b-4bda-959d-4c57d9fa264c.webp";
import styled from "styled-components";

const BannerCartCategory=({children})=>{
    return(
        <Wapper>
            <div className="p-3">
                <h1 className="text-font-slider mx-2 mb-1">دسته بندی های شاپ</h1>
                <p className="f-12 mx-2">راحت و سریع خرید کنید</p>
                <div className="container text-center ">
                    <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
                        {children}
                    </div>
                </div>
            </div>
        </Wapper>
    )
}
export default BannerCartCategory

const Wapper =styled.section`
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }
  .img-banner3 {
    width: 95%;
    height: 100px;
    object-fit: contain;
  }
`