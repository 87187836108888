import { IoIosArrowBack } from "react-icons/io";
import { TbDiscountCheck } from "react-icons/tb";

import { HeaderProfile } from "../../components";
import { MdContactSupport, MdPayment } from "react-icons/md";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { useEffect, useState } from "react";
import axios from "axios";
import { BiSolidBellRing } from "react-icons/bi";
import { LuWallet } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PaymentOrder = () => {
  const [basket, setBasket] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wallet, setWallet] = useState([]);
  const [loadingWallet, setLoadingWallet] = useState(true);
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getBasketRefreshToken();
    getWalletRefreshToken();
  }, []);

  const getBasketRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getBasket();
      }
    } catch (error) {}
  };

  const getBasket = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `shoppingcart/`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);

        setBasket(response.data);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 401) {
          getBasketRefreshToken();
        }
      });
  };

  ///wallet

  const getWalletRefreshToken = async () => {
    setLoadingWallet(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getWallet();
      }
    } catch (error) {}
  };

  const getWallet = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `memberwallet/`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingWallet(false);

        setWallet(response.data.balance);
      })
      .catch((error) => {
        setLoadingWallet(false);

        if (error.response.status === 401) {
          getWalletRefreshToken();
        }
      });
  };

  ////send address id

  const sendAddressRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        sendAddress();
      }
    } catch (error) {}
  };
  const sendAddress = async () => {
    let token = await localStorage.getItem("token_access");

    axios({
      method: "put",
      url: "shoppingcart/checkout/",
      data: {
        address_id: state.address_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate("/profile");
        toast.success("خرید شما با موفقیت ثبت شد.");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          sendAddressRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="پرداخت" />
      <div className="p-2" style={{ fontSize: "12px", marginBottom: "150px" }}>
        <div className="bg-light p-1" style={{ height: "30px" }}>
          <p>
            سبد خرید
            <IoIosArrowBack />
            تعیین آدرس
            <IoIosArrowBack />
            <span style={{ color: "#b400ae" }}>پرداخت</span>
          </p>
        </div>

        <div className=" mx-2 " style={{ marginTop: "100px" }}>
          <div className="d-flex ">
            <span className="h3 text-secondary">
              <MdPayment />
            </span>
            <p style={{ fontSize: "15px" }} className="mt-1 pe-2">
              {" "}
              انتخاب روش پرداخت :
            </p>
          </div>
          <div className=" my-1">
            <input
              type="radio"
              name="options"
              id="bank"
              className="btn-check"
            />
            <label htmlFor="bank" className="btn btn-outline-purple w-100">
              درگاه بانکی
            </label>
          </div>

          <div className="my-2">
            <input
              type="radio"
              name="options"
              id="wallet"
              className="btn-check"
              data-bs-toggle="offcanvas"
              data-bs-target="#walletcanvasBottom"
              aria-controls="walletcanvasBottom"
            />
            <label htmlFor="wallet" className="btn btn-outline-purple w-100 ">
              کیف پول
            </label>
          </div>
        </div>

        {/* <div className=" mt-5 mx-2">
          <div className="d-flex ">
            <span className="h3 text-secondary">
              <TbDiscountCheck />
            </span>
            <p style={{ fontSize: "15px" }} className="mt-1 pe-2">
              افزودن کد تخفیف :
            </p>
          </div>
          <input
            type="text"
            id="off"
            className="w-100  rounded-2 px-3 form-control"
            placeholder="کد تخفیف را اینجا وارد کنید"
          />
          <button className="btn btn-secondary w-100 mt-2"> بررسی کد</button>
        </div> */}

        <div
          className="d-flex justify-content-between px-3 py-2 align-items-center bg-white w-100 shadow-lg"
          style={{ position: "fixed", bottom: "70px", left: "0px" }}
        >
          <div>
            {loading ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "3vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <p className="m-0" style={{ fontSize: "16px" }}>
                  قابل پرداخت
                </p>
                <p className="m-0 fw-bold" style={{ fontSize: "16px" }}>
                  {" "}
                  {numberWithCommas(basket[0].total_price)} ریال
                </p>
              </>
            )}
          </div>
          <button
            className="btn btn-red "
            style={{ padding: "10px 25px" }}
            onClick={sendAddressRefreshToken}
          >
            پرداخت
          </button>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-bottom h-50"
        id="walletcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title " id="offcanvasBottomLabel">
            <LuWallet className="ms-2" />
            کیف پول
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body small">
          <p className="mb-0 mt-2"> موجودی کیف پول : </p>
          <div
            className="py-2 px-3 "
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              direction: "ltr",
            }}
          >
            {wallet !== undefined ? numberWithCommas(wallet) : 0}
          </div>
          <div className="d-flex justify-content-around mt-5">
            <button className="btn btn-primary w-50">افزایش موجودی </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOrder;
