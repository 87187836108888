
import { HeaderProfile, OrderMyByes } from "../../components";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";

const MyBuys = () => {
  const [orders, setOrders] = useState([]);
  const [loadingDuring, setLoadingDuring] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(true);
  const [loadingDelivered, setLoadingDelivered] = useState(true);
  const [loadingConfirmation, setLoadingConfirmation] = useState(true);
  const [loadingShipped, setLoadingShipped] = useState(true);

  const [orderDuring, setOrderDuring] = useState([]);
  const [orderFailed, setOrderFailed] = useState([]);
  const [orderDelivered, setOrderDelivered] = useState([]);
  const [orderConfirmation, setOrderConfirmation] = useState([]);
  const [OrderShipped, setOrderShipped] = useState([]);

  ///
  useEffect(() => {
    initialorderRefreshToken();
  }, []);

  ///shippedorder

  const ShippedOrderRefreshToken = async () => {
    setLoadingShipped(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        ShippedOrder();
      }
    } catch (error) {}
  };

  const ShippedOrder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "shippedorder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShipped(false);
        setOrderShipped(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        setLoadingShipped(false);

        if (error.response.status === 401) {
          ShippedOrderRefreshToken();
        }
      });
  };

  ///confirmedorder

  const ConfirmationOrderRefreshToken = async () => {
    setLoadingConfirmation(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        ConfirmationOrder();
      }
    } catch (error) {}
  };

  const ConfirmationOrder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "confirmedorder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingConfirmation(false);
        setOrderConfirmation(response.data);
        

        // console.log(response.data)
      })
      .catch((error) => {
        setLoadingConfirmation(false);

        if (error.response.status === 401) {
          ConfirmationOrderRefreshToken();
        }
      });
  };

  ///rejectedorder

  const rejectedOrderRefreshToken = async () => {
    setLoadingFailed(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        RejectedOrder();
      }
    } catch (error) {}
  };

  const RejectedOrder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "rejectedorder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingFailed(false);
        setOrderFailed(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        setLoadingFailed(false);

        if (error.response.status === 401) {
          rejectedOrderRefreshToken();
        }
      });
  };

  ///deliveredorder

  const deliveredorderRefreshToken = async () => {
    setLoadingDelivered(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        deliveredorder();
      }
    } catch (error) {}
  };

  const deliveredorder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "deliveredorder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingDelivered(false);
        setOrderDelivered(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        setLoadingDelivered(false);

        if (error.response.status === 401) {
          deliveredorderRefreshToken();
        }
      });
  };

  ///initialorder

  const initialorderRefreshToken = async () => {
    setLoadingDuring(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        initialorder();
      }
    } catch (error) {}
  };

  const initialorder = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "initialorder/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingDuring(false);
        setOrderDuring(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        setLoadingDuring(false);

        if (error.response.status === 401) {
          initialorderRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="خریدهای من" />
      <ul className="nav nav-pills nav-buy " id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="pills-During-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-During"
            type="button"
            role="tab"
            aria-controls="pills-During"
            aria-selected="true"
            onClick={initialorderRefreshToken}
            style={{ width: "50px" }}

          >
            جاری
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-confirmation-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-confirmation"
            type="button"
            role="tab"
            aria-controls="pills-confirmation"
            aria-selected="false"
            onClick={ConfirmationOrderRefreshToken}
          >
            {" "}
            تایید شده
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-Canceled-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Canceled"
            type="button"
            role="tab"
            aria-controls="pills-Canceled"
            aria-selected="false"
            onClick={rejectedOrderRefreshToken}
          >
            لغو شده
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-failed-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-failed"
            type="button"
            role="tab"
            aria-controls="pills-failed"
            aria-selected="false"
            onClick={ShippedOrderRefreshToken}
          >
            ارسال شده
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-delivered-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-delivered"
            type="button"
            role="tab"
            aria-controls="pills-delivered"
            aria-selected="false"
            onClick={deliveredorderRefreshToken}
          >
            تحویل شده
          </button>
        </li>
      </ul>

      <div
        style={{
          marginBottom: "100px",
          marginTop: "40px",
        }}
      >
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-During"
            role="tabpanel"
            aria-labelledby="pills-During-tab"
          >
            {loadingDuring ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : orderDuring.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما در حال حاضر خریدی ندارید!</p>
              </div>
            ) : (
              <OrderMyByes arrayorder={orderDuring} page="during" />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-confirmation"
            role="tabpanel"
            aria-labelledby="pills-confirmation-tab"
          >
            {loadingConfirmation ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : orderConfirmation.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما خرید تایید شده ندارید!</p>
              </div>
            ) : (
              <OrderMyByes arrayorder={orderConfirmation} page={"confirmed"} />
            
            )}
            
          </div>
          <div
            className="tab-pane fade"
            id="pills-Canceled"
            role="tabpanel"
            aria-labelledby="pills-Canceled-tab"
          >
            {loadingFailed ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : orderFailed.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما خرید لغو شده ندارید!</p>
              </div>
            ) : (
              <OrderMyByes arrayorder={orderFailed} page={"rejected"} />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-failed"
            role="tabpanel"
            aria-labelledby="pills-failed-tab"
          >
            {loadingShipped ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : OrderShipped.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">شما خرید ارسال شده ندارید!</p>
              </div>
            ) : (
              <OrderMyByes arrayorder={OrderShipped} page="shipped" />
            )}
          </div>

          <div
            className="tab-pane fade"
            id="pills-delivered"
            role="tabpanel"
            aria-labelledby="pills-delivered-tab"
          >
            {loadingDelivered ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : orderDelivered.length === 0 ? (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ height: "70vh" }}
              >
                <p className="text-secondary">
                  شما خرید تحویل داده شده ندارید!
                </p>
              </div>
            ) : (
              <OrderMyByes arrayorder={orderDelivered} page="delivered" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBuys;
