import { BaseApi } from './BaseApi'

const extendedBannerApi = BaseApi.injectEndpoints({
    tagTypes: ["BANNER"],
    endpoints:  (builder)=>({
        getBannerShow: builder.query({
            query: () => 'banner/',
            providesTags: (result, error, id) => [{ type: 'BANNER', id }],
        }),
    }),
    overrideExisting: false,
})

export const { useGetBannerShowQuery  } = extendedBannerApi