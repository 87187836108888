const Progress = ({ pageNumber }) => {
  return (
    <>
      {pageNumber === "1" ? (
        <>
          <div className="mt-3 mx-2">
            <div className="d-flex justify-content-center">
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>گروه شغلی</p>
              </div>
              <div
                className=" mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",

                  position: "relative",
                }}
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "10px",
                    width: "50px",
                    height: "5px",
                    backgroundColor: "blue",
                    position: "absolute",
                    top: "-1px",
                  }}
                ></div>
              </div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    // backgroundColor: "red",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>مشخصات</p>
              </div>
              <div
                className="mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",
                  // backgroundColor: "red",
                }}
              ></div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    // backgroundColor: "red",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>روزکاری</p>
              </div>
            </div>
          </div>
        </>
      ) : pageNumber === "2" ? (
        <>
          <div className="mt-3 mx-2">
            <div className="d-flex justify-content-center">
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>گروه شغلی</p>
              </div>
              <div
                className=" mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",
                  backgroundColor: "blue",
                }}
              ></div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>مشخصات</p>
              </div>
              <div
                className=" mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",

                  position: "relative",
                }}
              >
                <div
                  className=" "
                  style={{
                    border: "1px solid blue",
                    borderRadius: "10px",
                    width: "50px",
                    height: "5px",
                    backgroundColor: "blue",
                    position: "absolute",
                    top: "-1px",
                  }}
                ></div>
              </div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    // backgroundColor: "red",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>روزکاری</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-3 mx-2">
            <div className="d-flex justify-content-center">
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>گروه شغلی</p>
              </div>
              <div
                className=" mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",
                  backgroundColor: "blue",
                }}
              ></div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>مشخصات</p>
              </div>
              <div
                className="mt-2"
                style={{
                  border: "1px solid blue",
                  borderRadius: "10px",
                  width: "100px",
                  height: "5px",
                  backgroundColor: "blue",
                }}
              ></div>
              <div
                style={{ width: "50px" }}
                className="d-flex flex-column align-items-center"
              >
                <div
                  className=""
                  style={{
                    border: "1px solid blue",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                  }}
                ></div>
                <p style={{ fontSize: "11px", marginTop: "7px" }}>روزکاری</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Progress;
