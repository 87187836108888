import { useNavigate } from "react-router-dom";
import "./style.css";

const AddressFilds = ({
  btn1,
  btn2,
  createNavigate,
  exitNavigate,
  onChangePlak,
  valuePlak,
  onChangeVahed,
  valueVahed,
  onChangeTitle,
  valueTitle,
  onChangeCodePosti,
  valueCodePosti,
  onChangePhone,
  valuePhone,
  onChangeAddress,
  valueAddress,
  error,
  btnLoading1,
  btnLoading2,
}) => {
  return (
    <>
      <div className="row mx-3 my-1 gy-3" style={{ paddingBottom: "120px" }}>
        <div className="col-12 form-group">
          <label htmlFor="title">عنوان آدرس</label>
          <input
            type="text"
            className="form-control"
            id="title"
            onChange={onChangeTitle}
            value={valueTitle}
          />
        </div>

        <div className="col-12 form-group">
          <label htmlFor="textarea">
            آدرس <span className="text-danger">*</span>
            <span className="text-primary" style={{ fontSize: "12px" }}>
              {" "}
              (محدوده کیلان)
            </span>
          </label>
          <textarea
            className="form-control "
            rows="3"
            id="textarea"
            onChange={onChangeAddress}
            value={valueAddress}
          />
        </div>

        <div className="col-6 form-group">
          <label htmlFor="plak">پلاک</label>
          <input
            type="text"
            className="form-control"
            id="plak"
            onChange={onChangePlak}
            value={valuePlak}
          />
        </div>

        <div className="col-6 form-group">
          <label htmlFor="vahed">واحد</label>
          <input
            type="text"
            className="form-control"
            id="vahed"
            onChange={onChangeVahed}
            value={valueVahed}
          />
        </div>

        <div className="col-12 form-group">
          <label htmlFor="postcode">کدپستی</label>
          <input
            type="text"
            className="form-control"
            id="postcode"
            onChange={onChangeCodePosti}
            value={valueCodePosti}
          />
        </div>

        <div className="col-12 form-group">
          <label htmlFor="phone">
            شماره موبایل <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="phone"
            onChange={onChangePhone}
            value={valuePhone}
          />
        </div>
        {error !== "" && (
          <p className="text-danger d-flex justify-content-center">{error}</p>
        )}

        <div className="row m-0 px-3 justify-content-around btn-div  ">
          {btnLoading2 ? (
            <button
              class=" col btn btn-outline-primary  btn-edit"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="col  btn btn-outline-primary btn-edit"
              onClick={exitNavigate}
            >
              {btn2}
            </button>
          )}
          {btnLoading1 ? (
            <button
              class=" col btn btn-primary  btn-edit"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="col  btn btn-primary btn-edit"
              onClick={createNavigate}
            >
              {btn1}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddressFilds;
