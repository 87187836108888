export function findfullimage(x1, x2, x3, x4, x5) {
  if (x1 !== null) {
    // return `${window.location.origin}+no-image.png`;
    return x1;
  } else if (x2 !== null) {
    return x2;
  } else if (x3 !== null) {
    return x3;
  } else if (x4 !== null) {
    return x4;
  } else if (x5 !== null) {
    return x5;
  }else{
    return false
  }
}
