import React, { Suspense, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './styles.css'
import axios from "axios";

import {
    SearchBarButton,
    LoadingSkeleton,
    LoadingCart,
    LoadingSliderTop,
    LoadingBanner
} from "../../components";

import { useGetSliderShowQuery } from "../../reducers/SlideShowSlice";
import { useGetBannerShowQuery } from "../../reducers/BannerSlice";
import { useGetBannerItemShowQuery } from "../../reducers/BannerItemSlice";
import { useGetOfferQuery } from "../../reducers/OfferSlice";
import { useGetOfferItemQuery } from "../../reducers/OfferItemSlice";
import SliderBannerHomePageMemo from "./SliderBannerHomePageMemo";
import SliderSuggestionCategoryMemo from "./SliderSuggestionCategoryMemo";
import SliderCartProductsMemo from "./SliderCartProductsMemo";
import BannerCartOneItemMemo from "./BannerCartOneItemMemo";
import BannerCartFourItemMemo from "./BannerCartFourItemMemo";
import BannerCartCategoryMemo from "./BannerCartCategoryMemo";

// useEffect

const HomePage = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        const refreshTokenSet=async ()=>{
            if(window.location.search!==""){
            const refresh=window.location.search.split("=")[1]
            await localStorage.setItem("token_refresh",refresh)
            await refreshtoken()
            }
        }
        refreshTokenSet()
    },[])

    const refreshtoken = async () => {
        const refresh_Token = await localStorage.getItem('token_refresh');
        try {
            const {data, status} = await axios.post('authentication/token/refresh/', {
                refresh: refresh_Token,
            });
            if (status === 200 || status === 201) {
                await localStorage.setItem('token_access', data.access);
                  navigate(
                    `/category/`
                );
                console.log("ok")
            }
        } catch (error) {
           console.log(error)
        }
    };

     
    
};

export default HomePage;