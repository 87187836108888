import { Outlet } from "react-router-dom";
import { NavBarBottom } from "../components";
import { memo, useEffect, useState } from "react";
import Loading from "../assets/images/loading.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";

const MainLayout = () => {
  // const [isLoading, setIsLoading] = useState(true);
  //
  // const handleLoading = () => {
  //   setTimeout(()=>{
  //       setIsLoading(false);
  //   },[2000])
  // }
  //
  // useEffect(()=>{
  //     window.addEventListener("load",handleLoading);
  //     return () => window.removeEventListener("load",handleLoading);
  // },[])
  //
  // if(isLoading){
  //     return (
  //         <div className={`d-flex justify-content-center align-items-center h-100-vh`} >
  //             <img src={Loading} className="image-loading-size" alt="loading"/>
  //         </div>
  //     )
  // }
  return (
    <div>
      <Outlet />
      <NavBarBottom />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default MainLayout;
