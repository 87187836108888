import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HeaderProfile } from "../../components";
import { toast } from "react-toastify";
import { GiCancel } from "react-icons/gi";
import { numberWithCommas } from "../../utils/numberWithCommas";
import Compressor from "compressorjs";

const EditMyProductAdmin = () => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [sendType, setSendType] = useState(null);
  const [inventoryStock, setInventoryStock] = useState(null);
  const [product, setProduct] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();
  const { shopid } = useParams();
  const { productid } = useParams();
  const [allCategory, setAllCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [btnLoading1, setBtnLoading1] = useState(false);
  const [btnLoading2, setBtnLoading2] = useState(false);
  const [imageForDel, setImgFordel] = useState(null);

  useEffect(() => {
    categoryGetRefreshToken();
    if (product === null) {
      productGetRefreshToken();
    } else {
      setTitle(product.title);
      setDescription(product.description);
      setPrice(product.price);
      setCategory(product.category);
      setSendType(product.sendType);
      setInventoryStock(product.inventoryStock);
      setImage1(product.image1);
      setImage2(product.image2);
      setImage3(product.image3);
      setImage4(product.image4);
      setImage5(product.image5);
    }
  }, [product]);

  const deleteOnClickImage1 = () => {
    setImgFordel("image1")
  };

  const deleteOnClickImage2 = () => {
    setImgFordel("image2")
  };

  const deleteOnClickImage3 = () => {
    setImgFordel("image3")
  };

  const deleteOnClickImage4 = () => {
    setImgFordel("image4")
  };

  const deleteOnClickImage5 = () => {
    setImgFordel("image5")

  };
  const removeRenderImage=()=>{
    if(imageForDel == "image1"){
      setImage1(null)
    }
    if(imageForDel == "image2"){
      setImage2(null)
    }
    if(imageForDel=="image3"){
      setImage3(null)
    }
    if(imageForDel == "image4"){
      setImage4(null)
    }
    if(imageForDel == "image5"){
      setImage5(null)
    }
    doDeleteImageRefreshToken()
  }
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };
  const onChangeSendType = (e) => {
    setSendType(e.target.value);
  };

  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };
  const onChangeInventoryStock = (e) => {
    setInventoryStock(e.target.value);
  };
  const onChangeImage1 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage1(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const onChangeImage2 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage2(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const onChangeImage3 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage3(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };

  const onChangeImage4 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage4(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const onChangeImage5 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage5(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const exitNavigate = () => {
    setBtnLoading1(true);
    navigate(`/profile/myshop/${shopid}`);
    setBtnLoading1(false);
  };

  const editNavigate = () => {
    setBtnLoading2(true);

    if (
      title !== "" &&
      title !== null &&
      price !== null &&
      price !== "" &&
      description !== "" &&
      description !== null &&
      inventoryStock !== null &&
      inventoryStock !== "" &&
      sendType !== "" &&
      sendType !== null &&
      category !== null &&
      category !== ""
    ) {
      editRefreshToken();
    } else {
      setBtnLoading2(false);
      toast.error(" هیچ فیلدی نباید خالی باشد!");
    }
  };

  const editRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        editput();
      }
    } catch (error) {}
  };

  const editput = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    form.append("title", title);
    // form.append("shop_id", shopid);
    form.append("description", description);
    form.append("price", price);
    form.append("sendType", sendType);
    form.append("inventoryStock", inventoryStock);
    if (image1 !== null && image1 !== product.image1) {
      form.append("image1", image1, image1.name);
    }
    if (image2 !== null && image2 !== product.image2) {
      form.append("image2", image2, image2.name);
    }
    if (image3 !== null && image3 !== product.image3) {
      form.append("image3", image3, image3.name);
    }
    if (image4 !== null && image4 !== product.image4) {
      form.append("image4", image4 , image4.name);
    }
    if (image5 !== null && image5 !== product.image5) {
      form.append("image5", image5, image5.name);
    }

    form.append("category", category);

    axios({
      method: "put",
      url: `servicejobsample/${productid}/`,
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (state.value === "productpage") {
          navigate(`/profile/myshop/${shopid}/myproduct/${productid}`);
        } else {
          navigate(`/profile/myshop/${shopid}`);
        }
        setBtnLoading2(false);
        toast.success("نمونه با موفقیت ویرایش شد");
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          }
        }
        if (error.response.status === 401) {
          editRefreshToken();
        }
        setBtnLoading2(false);
      });
  };
  /////

  const categoryGetRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        categoryget();
      }
    } catch (error) {}
  };

  const categoryget = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "servicejobsamplecategory/",

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAllCategory(response.data);
      })
      .catch((error) => {});
  };



  
  const doDeleteImageRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");

    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        doDeleteImage()
      }
    } catch (error) {}
  };

  const doDeleteImage = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    form.append("remove_image", imageForDel);
    axios({
      method: "PUT",
      url: `/servicejobsample/${productid}/removeimage/`,
      data:form,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
       
      })
      .catch((error) => {

       
      });
  };

  /////doDeleteImage("image1")

  const productGetRefreshToken = async () => {
    setLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        productGet();
      }
    } catch (error) {}
  };

  const productGet = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `servicejobsample/${productid}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        setProduct(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <HeaderProfile title_page=" ویرایش نمونه " />

      {loading ? (
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="row mx-3 my-1 gy-3" style={{ paddingBottom: "120px" }}>
          <div className="col-12 form-group">
            <label>
              عنوان نمونه <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              onChange={onChangeTitle}
              value={title}
            />
          </div>
          <div className="col-12 form-group">
            <label htmlFor="textarea">
              توضیحات <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control "
              rows="3"
              id="textarea"
              onChange={onChangeDescription}
              value={description}
            />
          </div>


          <div className="col-12 form-group">
            <label htmlFor="phone">
              {" "}
              گروه  <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={onChangeCategory}
            >
              {allCategory?.map((item, index) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>

          <div className="">
            <p>
              انتخاب تصویر 
              <br />{" "}
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {" "}
                ( شما می توانید۱ تا ۵ تصویر برای نمونه خود انتخاب کنید )
              </span>
            </p>

            <div className="row g-2">
              <div
                className="col-3 p-0"
                style={{ overflow: "hidden", position: "relative" }}
              >
                {image1 !== null && (
                  <button
                    className="border-0 rounded-circle text-secondary"
                    style={{ position: "absolute", top: "0px", left: "5px" }}
                    onClick={deleteOnClickImage1}
                    
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#reportcanvasBottom"
                    aria-controls="reportcanvasBottom"
                  >
                    <GiCancel />
                  </button>
                )}
                <label htmlFor="image_1" className="btn btn-outline-light">
                  <img
                    src={
                      image1 === null
                        ? window.location.origin + "/image/no_image.png"
                        : image1 === product.image1
                        ? image1
                        : URL.createObjectURL(image1)
                    }
                    style={{ width: "70px", height: "70px" }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image_1"
                  onChange={onChangeImage1}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              </div>
              <div
                className="col-3 p-0"
                style={{ overflow: "hidden", position: "relative" }}
              >
                {image2 !== null && (
                  <button
                    className="border-0 rounded-circle text-secondary"
                    style={{ position: "absolute", top: "0px", left: "5px" }}
                    onClick={deleteOnClickImage2}

                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#reportcanvasBottom"
                    aria-controls="reportcanvasBottom"
                  >
                    <GiCancel />
                  </button>
                )}
                <label htmlFor="image_2" className="btn btn-outline-light">
                  <img
                    src={
                      image2 === null
                        ? window.location.origin + "/image/no_image.png"
                        : image2 === product.image2
                        ? image2
                        : URL.createObjectURL(image2)
                    }
                    style={{ width: "70px", height: "70px" }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image_2"
                  onChange={onChangeImage2}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              </div>{" "}
              <div
                className="col-3 p-0"
                style={{ overflow: "hidden", position: "relative" }}
              >
                {image3 !== null && (
                  <button
                    className="border-0 rounded-circle text-secondary"
                    style={{ position: "absolute", top: "0px", left: "5px" }}
                    onClick={deleteOnClickImage3}

                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#reportcanvasBottom"
                    aria-controls="reportcanvasBottom"
                  >
                    <GiCancel />
                  </button>
                )}
                <label htmlFor="image_3" className="btn btn-outline-light">
                  <img
                    src={
                      image3 === null
                        ? window.location.origin + "/image/no_image.png"
                        : image3 === product.image3
                        ? image3
                        : URL.createObjectURL(image3)
                    }
                    style={{ width: "70px", height: "70px" }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image_3"
                  onChange={onChangeImage3}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              </div>
              <div
                className="col-3 p-0"
                style={{ overflow: "hidden", position: "relative" }}
              >
                {image4 !== null && (
                  <button
                    className="border-0 rounded-circle text-secondary"
                    style={{ position: "absolute", top: "0px", left: "5px" }}
                    onClick={deleteOnClickImage4}

                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#reportcanvasBottom"
                    aria-controls="reportcanvasBottom"
                  >
                    <GiCancel />
                  </button>
                )}
                <label
                  htmlFor="image_4"
                  className="btn btn-outline-light"
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  <img
                    src={
                      image4 === null
                        ? window.location.origin + "/image/no_image.png"
                        : image4 === product.image4
                        ? image4
                        : URL.createObjectURL(image4)
                    }
                    style={{ width: "70px", height: "70px" }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image_4"
                  onChange={onChangeImage4}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              </div>
              <div
                className="col-3 p-0"
                style={{ overflow: "hidden", position: "relative" }}
              >
                {image5 !== null && (
                  <button
                    className="border-0 rounded-circle text-secondary"
                    style={{ position: "absolute", top: "0px", left: "5px" }}
                    onClick={deleteOnClickImage5}

                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#reportcanvasBottom"
                    aria-controls="reportcanvasBottom"
                  >
                    <GiCancel />
                  </button>
                )}
                <label htmlFor="image_5" className="btn btn-outline-light">
                  <img
                    src={
                      image5 === null
                        ? window.location.origin + "/image/no_image.png"
                        : image5 === product.image5
                        ? image5
                        : URL.createObjectURL(image5)
                    }
                    style={{
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="image_5"
                  onChange={onChangeImage5}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              </div>
            </div>
          </div>

          <div
        className="offcanvas offcanvas-bottom h-25"
        id="reportcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-danger" id="offcanvasBottomLabel">
           
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
      
        <div className="offcanvas-body small">
        <div align="center">
                <h4 >آیا از حذف  عکس اطمینان دارید؟</h4>
                <br/><br/>
              
          <button
              className="btn btn-danger w-25 mx-1"
              type="button"
              onClick={()=>{removeRenderImage()}}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              بله
            </button>
            <button
              className="btn btn-secondary w-25 mx-1"
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              // onClick={()=>sendCompaint()}
            >
              خیر
            </button>
            </div>

        </div>
       
      </div>



          <div
            className="row m-0 px-3 justify-content-around btn-div bg-light "
            style={{
              position: "fixed",
              bottom: "68px",
              left: "0px",
              backgroundColor: "white",
              height: "60px",
              padding: "10px",
            }}
          >
            {btnLoading1 ? (
              <button
                className=" col btn btn-outline-primary btn-edit"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="col  btn btn-outline-primary btn-edit"
                onClick={exitNavigate}
              >
                انصراف
              </button>
            )}
            {btnLoading2 ? (
              <button
                className=" col btn btn-outline-primary btn-edit"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="col  btn btn-primary btn-edit"
                onClick={editNavigate}
              >
                ویرایش نمونه
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EditMyProductAdmin;
