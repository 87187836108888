import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderProfile } from "../../components";
import { toast } from "react-toastify";
import { GiCancel } from "react-icons/gi";
import Compressor from "compressorjs";

const CreateMyProductAdmin = () => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, sePrice] = useState(null);
  const [sendType, setSendType] = useState(null);
  const [inventoryStock, setInventoryStock] = useState(null);

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();
  const { shopid } = useParams();
  const [allCategory, setAllCategory] = useState([]);
  const [btnLoading1, setBtnLoading1] = useState(false);
  const [btnLoading2, setBtnLoading2] = useState(false);

  useEffect(() => {
    categoryGetRefreshToken();
  }, []);

  const deleteOnClickImage1 = () => {
    setImage1(null);
  };

  const deleteOnClickImage2 = () => {
    setImage2(null);
  };

  const deleteOnClickImage3 = () => {
    setImage3(null);
  };

  const deleteOnClickImage4 = () => {
    setImage4(null);
  };

  const deleteOnClickImage5 = () => {
    setImage5(null);
    console.log(image5);
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const onChangePrice = (e) => {
    sePrice(e.target.value);
  };
  const onChangeSendType = (e) => {
    setSendType(e.target.value);
    console.log(e.target.value);
  };

  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };
  const onChangeInventoryStock = (e) => {
    setInventoryStock(e.target.value);
  };

  const onChangeImage1 = (e) => {
    const handleCompressedUpload = (e) => {
      console.log("--------------" ,e.target.files[0])
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          // console.log(compressedResult)
          setImage1(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);

    if (e && e !== null) {
      console.log("im fill");
    }
  };
  const onChangeImage2 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage2(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const onChangeImage3 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage3(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };

  const onChangeImage4 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage4(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const onChangeImage5 = (e) => {
    const handleCompressedUpload = (e) => {
      new Compressor(e.target.files[0], {
        quality: 0.7,
        success: (compressedResult) => {
          setImage5(compressedResult);
        },
      });
    };
    handleCompressedUpload(e);
  };
  const exitNavigate = () => {
    setBtnLoading1(true);
    navigate(`/profile/myshop/${shopid}`);
    setBtnLoading2(false);
  };

  const createNavigate = () => {
    setBtnLoading2(true);
    if (
      title !== "" &&
      title !== null &&
      description !== "" &&
      description !== null &&
      category !== null &&
      category !== ""
    ) {
      createRefreshToken();
    } else {
      toast.error(" همه فیلدها باید پر شود!");
      setBtnLoading2(false);
    }
  };

  const createRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        createpost();
      }
    } catch (error) {}
  };

  const createpost = () => {
    let token = localStorage.getItem("token_access");
    const form = new FormData();
    form.append("title", title);
    form.append("serviceJob_id", shopid);
    form.append("description", description);
    form.append("price", price);
    form.append("sendType", sendType);
    form.append("inventoryStock", inventoryStock);
    if (image1 !== null) {
      form.append("image1", image1, image1.name);
    }

    if (image2 !== null) {
      form.append("image2", image2, image2.name);
    }
    if (image3 !== null) {
      form.append("image3", image3, image3.name);
    }
    if (image4 !== null) {
      form.append("image4", image4, image4.name);
    }
    if (image5 !== null) {
      form.append("image5", image5, image5.name);
    }

    form.append("category", category);

    axios({
      method: "post",
      url: "servicejobsample/",
      data: form,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        navigate(`/profile/myshop/${shopid}`);
        setBtnLoading2(false);
        toast.success("نمونه کار شما با موفقیت ایجاد شد!");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createRefreshToken();
        }
        setBtnLoading2(false);
      });
  };
  /////

  const categoryGetRefreshToken = async () => {
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        categoryget();
      }
    } catch (error) {}
  };

  const categoryget = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: "servicejobsamplecategory/",

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAllCategory(response.data);
      })
      .catch((error) => {});
  };

  /////

  return (
    <>
      <HeaderProfile title_page="افزودن نمونه کار " />
      <div className="row mx-3 my-1 gy-3" style={{ paddingBottom: "120px" }}>
        <div className="col-12 form-group">
          <label>
             عنوان<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeTitle}
            value={title}
          />
        </div>
        <div className="col-12 form-group">
          <label htmlFor="textarea">
            توضیحات <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control "
            rows="3"
            id="textarea"
            onChange={onChangeDescription}
            value={description}
          />
        </div>


        {/* <div className="col-12 form-group">
          <label htmlFor="postcode">
            نحوه ارسال <span className="text-danger">*</span>
          </label>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={onChangeSendType}
          >
            <option selected>نوع ارسال را مشخص کنید</option>
            <option value="1">حضوری</option>
            <option value="2">ارسال با پیک</option>
          </select>
        </div> */}

        <div className="col-12 form-group">
          <label htmlFor="phone">
            {" "}
            گروه خدماتی<span className="text-danger">*</span>
          </label>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={onChangeCategory}
          >
            <option selected>گروه خدماتی را انتخاب کنید</option>
            {allCategory?.map((item, index) => (
              <option value={item.id}>{item.title}</option>
            ))}
          </select>
        </div>

        <div className="">
          <p>
            انتخاب تصویر نمونه:
            <br />{" "}
            <span className="text-danger" style={{ fontSize: "12px" }}>
              {" "}
              ( شما می توانید۱ تا ۵ تصویر برای خدمات خود انتخاب کنید )
            </span>
          </p>

          <div className="row g-2">
            <div className="col-3 p-0">
              <label
                htmlFor="image_1"
                className="btn btn-outline-light"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <button
                  className="border-0 rounded-circle text-secondary"
                  style={{ position: "absolute", top: "0px", left: "5px" }}
                  onClick={deleteOnClickImage1}
                >
                  <GiCancel />
                </button>
                <img
                  src={
                    image1 === null
                      ? window.location.origin + "/image/no_image.png"
                      : URL.createObjectURL(image1)
                  }
                  style={{ width: "70px", height: "70px" }}
                />
              </label>
              <input
                type="file"
                accept="image/*"
                id="image_1"
                onChange={onChangeImage1}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
            <div className="col-3 p-0">
              <label
                htmlFor="image_2"
                className="btn btn-outline-light"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <button
                  className="border-0 rounded-circle text-secondary"
                  style={{ position: "absolute", top: "0px", left: "5px" }}
                  onClick={deleteOnClickImage2}
                >
                  <GiCancel />
                </button>
                <img
                  src={
                    image2 === null
                      ? window.location.origin + "/image/no_image.png"
                      : URL.createObjectURL(image2)
                  }
                  style={{ width: "70px", height: "70px" }}
                />
              </label>
              <input
                type="file"
                accept="image/*"
                id="image_2"
                onChange={onChangeImage2}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>{" "}
            <div className="col-3 p-0">
              <label
                htmlFor="image_3"
                className="btn btn-outline-light"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <button
                  className="border-0 rounded-circle text-secondary"
                  style={{ position: "absolute", top: "0px", left: "5px" }}
                  onClick={deleteOnClickImage3}
                >
                  <GiCancel />
                </button>
                <img
                  src={
                    image3 === null
                      ? window.location.origin + "/image/no_image.png"
                      : URL.createObjectURL(image3)
                  }
                  style={{ width: "70px", height: "70px" }}
                />
              </label>
              <input
                type="file"
                accept="image/*"
                id="image_3"
                onChange={onChangeImage3}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
            <div className="col-3 p-0">
              <label
                htmlFor="image_4"
                className="btn btn-outline-light"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <button
                  className="border-0 rounded-circle text-secondary"
                  style={{ position: "absolute", top: "0px", left: "5px" }}
                  onClick={deleteOnClickImage4}
                >
                  <GiCancel />
                </button>
                <img
                  src={
                    image4 === null
                      ? window.location.origin + "/image/no_image.png"
                      : URL.createObjectURL(image4)
                  }
                  style={{ width: "70px", height: "70px" }}
                />
              </label>
              <input
                type="file"
                accept="image/*"
                id="image_4"
                onChange={onChangeImage4}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
            <div className="col-3 p-0">
              <label
                htmlFor="image_5"
                className="btn btn-outline-light"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <button
                  className="border-0 rounded-circle text-secondary"
                  style={{ position: "absolute", top: "0px", left: "5px" }}
                  onClick={deleteOnClickImage5}
                >
                  <GiCancel />
                </button>
                <img
                  src={
                    image5 === null
                      ? window.location.origin + "/image/no_image.png"
                      : URL.createObjectURL(image5)
                  }
                  style={{
                    width: "70px",
                    height: "70px",
                  }}
                />
              </label>
              <input
                type="file"
                accept="image/*"
                id="image_5"
                onChange={onChangeImage5}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="row m-0 px-3 justify-content-around btn-div bg-light "
          style={{
            position: "fixed",
            bottom: "68px",
            left: "0px",
            backgroundColor: "white",
            height: "60px",
            padding: "10px",
          }}
        >
          {btnLoading1 ? (
            <button
              className=" col btn btn-outline-primary btn-edit"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="col  btn btn-outline-primary btn-edit"
              onClick={exitNavigate}
            >
              انصراف
            </button>
          )}
          {btnLoading2 ? (
            <button
              className=" col btn btn-outline-primary btn-edit"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="col  btn btn-primary btn-edit"
              onClick={createNavigate}
            >
              افزودن نمونه کار
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateMyProductAdmin;
