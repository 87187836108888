import styled from "styled-components";
import {LoadingSkeleton} from "../index";

const LoadingCart = () => {
    return (
        <Wapper >
            <div
                className="card h-100 d-flex justify-content-center align-items-center overflow-hidden ">
                <div className="card-image-slider w-100 h-100">
                    <LoadingSkeleton height="130px" width="100%"/>
                </div>
                <div className="card-body border-top-custom w-100 px-1 py-2 height-60">
                    <LoadingSkeleton height="10px" width="90%" borderRadius={2}/>
                    <LoadingSkeleton height="10px" width="80%" borderRadius={2}/>
                    <LoadingSkeleton height="10px" width="60%" borderRadius={2}/>
                </div>
                <div className="card-footer bg-transparent border-0 px-1 py-3 w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <LoadingSkeleton height="10px" width="100%" borderRadius={2}/>
                    </div>
                </div>
            </div>
        </Wapper>
    )
}

export default LoadingCart


const Wapper = styled.section`
  padding-right: 3px;
  padding-left: 3px;
  .card-image-slider {
    padding: 0.25rem;
    width: 8.25rem;
    height: 8.25rem;
    min-width: 8.25rem;
    min-height: 8.25rem;
  }
  .height-60 {
    height: 60px;
  }
  .border-top-custom {
    border-top: 1px solid #ccc;
  }
  
`