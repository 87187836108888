import {NavLink} from "react-router-dom";
import {AiFillShop} from "react-icons/ai";
import {TbCategory2} from "react-icons/tb";
import {MdOutlineShoppingBasket} from "react-icons/md";
import {HiOutlineUser} from "react-icons/hi";
import styled from "styled-components";

const NavBarBottom=()=>{
    return(
        <Wapper>
            <nav className="navbar fixed-bottom shadow-box-navigation-bottom ">
                <div className="container ">
                    <ul className="navbar-nav display-con me-auto mb-2 mb-lg-0 navigate-bottom">
                        {/* <li className="nav-item">
                            <NavLink
                                className={({isActive, isPending}) =>
                                    isActive ? "nav-link active-nav " : "nav-link"
                                }
                                aria-current="page"
                                to="/"
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <AiFillShop size={"1.2em"}/>
                                    <span className="f-12 fw-bold">خدمات</span>
                                </div>
                            </NavLink>
                        </li> */}
                        <li className="nav-item">
                            <NavLink
                                className={({isActive, isPending}) =>
                                    isActive ? "nav-link active-nav" : "nav-link"
                                }
                                aria-current="page"
                                to="/category"
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <TbCategory2 size={"1.2em"}/>
                                    <span className="f-12 fw-bold">رزرو حق آب</span>
                                </div>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink
                                className={({isActive, isPending}) =>
                                    isActive ? "nav-link active-nav" : "nav-link"
                                }
                                aria-current="page"
                                to="/orders"
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <MdOutlineShoppingBasket size={"1.2em"}/>
                                    <span className="f-12 fw-bold">سبد خرید</span>
                                </div>
                            </NavLink>
                        </li> */}
                        <li className="nav-item">
                            <NavLink
                                className={({isActive, isPending}) =>
                                    isActive ? "nav-link active-nav" : "nav-link"
                                }
                                aria-current="page"
                                to="/profile"
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <HiOutlineUser size={"1.2em"}/>
                                    <span className="f-12 fw-bold">تاریخچه رزرو</span>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </Wapper>
    )
}

export default NavBarBottom


const Wapper = styled.section`
  
  //navigation bar bottom
  .display-con {
    display: contents;
  }

  .shadow-box-navigation-bottom {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #fff;
  }

  .active-nav {
    color: #b400ae;
  }

  .size {
    height: 230px;
  }

  //navigation bar bottom


`;