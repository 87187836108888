import {BaseApi} from './BaseApi'

const extendedOfferItemApi = BaseApi.injectEndpoints({
    tagTypes: ["OFFER_ITEM"],
    endpoints: (builder) => ({
        getOfferItem: builder.query({
            query: () => 'offeritem/',
            providesTags: (result, error, id) => [{ type: 'OFFER_ITEM', id }],
        }),
    }),
    overrideExisting: false,
})

export const {useGetOfferItemQuery} = extendedOfferItemApi