import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { FaTrashCan } from "react-icons/fa6";
import { findfullimage } from "../../utils/findfullimage";

// {findfullimage(
//   item.image1,
//   item.image2,
//   item.image3,
//   item.image4,
//   item.image5
// ) !== false ? (
//   <img
//     src={findfullimage(
//       item.image1,
//       item.image2,
//       item.image3,
//       item.image4,
//       item.image5
//     )}
//     className="img-fluid"
//     alt="test"
//     style={{ height: "90px", objectFit: "contain" }}
//   />
// ) : (
//   <img
//     src={window.location.origin + "/image/no_image.png"}
//     className="img-fluid"
//     alt="test"
//     style={{ height: "90px", objectFit: "contain" }}
//   />
// )}

const AdminItemPersonalShope = ({
  title,
  // price,
  image1,
  image2,
  image3,
  image4,
  image5,
  to,
  editto,
  // rate,
  onClickTrash,
}) => {
  return (
    <div className="col-6 ">
      <div style={{ padding: "2px" }}>
        <div
          className="card  p-0  bg-light"
          style={{ borderRadius: "10px", overflow: "hidden" }}
        >
          <Link
            to={to}
            style={{
              textDecoration: "none",
              padding: "0px",
              height: "120px",
              objectFit: "contain",
            }}
            className="d-flex justify-content-center"
          >
            {findfullimage(image1, image2, image3, image4, image5) !== false ? (
              <img
                src={findfullimage(image1, image2, image3, image4, image5)}
                className="img-fluid"
                alt="test"
                style={{ height: "90px", objectFit: "contain" }}
              />
            ) : (
              <img
                src={window.location.origin + "/image/no_image.png"}
                className="img-fluid"
                alt="test"
                style={{ height: "90px", objectFit: "contain" }}
              />
            )}
          </Link>
          <div className="card-body  text-secondary ">
            <p className="card-title  " style={{ fontSize: "12px" }}>
              {title}
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <p
                className="card-text m-0 float-start"
                style={{ fontSize: "13px" }}
              >
                {/* {price} ریال */}
              </p>
            </div>
          </div>
          <div className="card-footer py-1 px-3  d-flex justify-content-between">
            <div>
              <Link
                style={{ textDecoration: "none", color: "#b400ae" }}
                to={editto}
                state={{ value: "mainpage" }}
              >
                <MdEdit size="20px" />
              </Link>
              <button className="border-0" onClick={onClickTrash}>
                <FaTrashCan size="15px" color="red" className="me-3" />
              </button>
            </div>

            <div>
              {/* <span style={{ fontSize: "12px", marginLeft: "3px" }}>
                {rate}
              </span> */}

              <FaStar size={"0.8em"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminItemPersonalShope;
