import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaShop } from "react-icons/fa6";
import { FaBasketShopping } from "react-icons/fa6";
import { FaAddressBook } from "react-icons/fa6";
import { RiBillFill } from "react-icons/ri";
import { MdContactSupport } from "react-icons/md";
import { FaCircleUser } from "react-icons/fa6";
import { FaRegUserCircle } from "react-icons/fa";
import { BiSolidBellRing } from "react-icons/bi";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';

const ProfileUser = () => {
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [waterRights, setWaterRights] = useState([]);

  /////

  useEffect(() => {
    getWaterrightsRefreshToken()
  }, []);

  /////
  const return_sts = (stsnum)=>{
    if(stsnum == 1)
      return <ourglassEmptyIcon/>
    else if(stsnum==2)
      return <CheckIcon/>
    else if(stsnum==3)
      return <WarningAmberIcon/>
    return <WarningAmberIcon/>
  }
  const convert_date = (dt)=>{
    const timestamp = dt;
  const [date, timeAndOffset] = timestamp.split('T');
  const [time, offset] = timeAndOffset.split('+');
  return ({"date":date, "time":time, "offset":offset})
// console.log("Date:", date);
// console.log("Time:", time);
// console.log("Offset:", offset);
  }
  const getWaterrightsRefreshToken = async () => {
    setUserLoading(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getWaterrights();
      }
    } catch (error) {}
  };

  const getWaterrights= () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `reswaterrights/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setUserLoading(false);

        setWaterRights(response.data);
      })
      .catch((error) => {
        setUserLoading(false);

        if (error.response.status === 401) {
          getWaterrightsRefreshToken();
        }
      });
  };

  return (
<>
<div style={{padding:"10px"}}>
      {waterRights?(
        
            waterRights.slice().reverse().map((item, index) => (

                <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{fontSize:"10px"}}
                >
     

                  {convert_date(item.from_date)['date']}     <br/> {convert_date(item.to_date)['date']}
                </AccordionSummary>
                <AccordionDetails>
                  وضعیت تایید : {item.status ===1?("ثبت اولیه"):(item.status===2?("تایید شده"):("رد شده") )}
                  <br/>
                  وضعیت پرداخت : {item.payment_status===false?("پرداخت نشده"):("پرداخت شده")}
                  <br/>
                  {item.reason_for_rejection?(item.reason_for_rejection):""}


                </AccordionDetails>
              </Accordion>

        
      ))):<p>تاریخچه</p>}
</div>
</>

  );
};

export default ProfileUser;
