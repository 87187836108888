import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import ShopLayout from "../layout/ShopLayout";
import {
  CategoryProducts,
  HomePage,
  OrdersShop,
  ProfileUser,
  SearchPage,
  MyShopReg,
  Address,
  EditAddress,
  CreateAddress,
  TimeSendOrderShop,
  PaymentOrder,
  AddressCheckBox,
  Wallet,
  PersonalShope,
  ProductPage,
  AllProduct,
  AllShop,
  MyBuys,
  AdminPersonalShop,
  CreateMyProductAdmin,
  EditMyProductAdmin,
  AdminProduct,
  AdminOrder,
  ItemOrderMyShop,
  ItemOrderMyShopHistory,
  ItemMyByesconfirmed,
  ItemMyByesrejected,
  ItemMyByesdelivered,
  ItemMyByeshipped,
  ItemMyByesduring,
  CategoryItem,
} from "../pages";
import AddressLayout from "../layout/AddressLayout";
import OrderLayout from "../layout/OrderLayout";
import CategoryLayout from "../layout/CategoryLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: (
      <h3 className="text-center">چیزی پیدا نکردیم متاسفانه ....</h3>
    ),
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/category/",
        element: <CategoryLayout />,
        children: [
          {
            index: true,
            element: <CategoryProducts />,
          },
          {
            path: "shop/:shopid",
            element: <PersonalShope />,
          },
          {
            path: "product/:productid",
            element: <ProductPage />,
          },
          {
            path: "products",
            element: <AllProduct />,
          },
          {
            path: "shops",
            element: <AllShop />,
          },
          {
            path: "categoryitem/:categoryid",
            element: <CategoryItem />,
          },
        ],
      },
      {
        path: "/orders/",
        element: <OrderLayout />,
        children: [
          {
            index: true,
            element: <OrdersShop />,
          },
          {
            path: "checkout/",
            element: <TimeSendOrderShop />,
          },
          {
            path: "checkout/edit",
            element: <AddressCheckBox />,
          },
          {
            path: "payment/",
            element: <PaymentOrder />,
          },
          {
            path: "payment/wallet",
            element: <Wallet />,
          },
        ],
      },
      {
        path: "/profile/",
        element: <ShopLayout />,
        children: [
          {
            index: true,
            element: <ProfileUser />,
          },
          {
            path: "myShopReg",
            element: <MyShopReg />,
          },
          {
            path: "myshop/:shopid",
            element: <AdminPersonalShop />,
          },
          {
            path: "myshop/:shopid/createmyproduct",
            element: <CreateMyProductAdmin />,
          },
          {
            path: "myshop/:shopid/editmyproduct/:productid",
            element: <EditMyProductAdmin />,
          },
          {
            path: "myshop/:shopid/myproduct/:productid",
            element: <AdminProduct />,
          },
          {
            path: "myshop/:shopid/myordershop",
            element: <AdminOrder />,
          },
          {
            path: "myshop/:shopid/myordershop/:orderid",
            element: <ItemOrderMyShop />,
          },
          {
            path: "myshop/:shopid/myordershophistory/:orderid",
            element: <ItemOrderMyShopHistory />,
          },
          {
            path: "orders",
            element: <MyBuys />,
          },

          {
            path: "orders/confirmed/:orderid",
            element: <ItemMyByesconfirmed />,
          },
          {
            path: "orders/rejected/:orderid",
            element: <ItemMyByesrejected />,
          },
          {
            path: "orders/delivered/:orderid",
            element: <ItemMyByesdelivered />,
          },
          {
            path: "orders/shipped/:orderid",
            element: <ItemMyByeshipped />,
          },
          {
            path: "orders/during/:orderid",
            element: <ItemMyByesduring />,
          },
          {
            path: "address/",
            element: <AddressLayout />,
            children: [
              {
                index: true,
                element: <Address />,
              },
              {
                path: "edit/:id",
                element: <EditAddress />,
              },
              {
                path: "new-address",
                element: <CreateAddress />,
              },
            ],
          },
        ],
      },
      {
        path: "/search",
        element: <SearchPage />,
      },
    ],
  },
]);
