import { configureStore } from '@reduxjs/toolkit'
import {BaseApi} from "../reducers/BaseApi";
import {setupListeners} from "@reduxjs/toolkit/query";


export const store = configureStore({
    reducer: {
        [BaseApi.reducerPath]: BaseApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(BaseApi.middleware),
})

setupListeners(store.dispatch)