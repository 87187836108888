import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import { findfullimage } from "../../utils/findfullimage";

const ItemPersonalShope = ({
  title,
  price,
  image1,
  image2,
  image3,
  image4,
  image5,
  to,
  rate,
}) => {
  return (
    <div  className="col-6  ">
      <div style={{ padding: "2px" }}>
        <div
          className="card  px-1  bg-light"
          style={{ borderRadius: "10px", overflow: "hidden" }}
        >
          <Link
            to={to}
            style={{
              textDecoration: "none",
              padding: "0px",
              height: "120px",
              objectFit: "contain",
            }}
            className="d-flex justify-content-center"
          >
            {findfullimage(image1, image2, image3, image4, image5) !== false ? (
              <img
                src={findfullimage(image1, image2, image3, image4, image5)}
                className="img-fluid"
                alt="test"
                style={{ height: "90px", objectFit: "contain" }}
              />
            ) : (
              <img
                src={window.location.origin + "/image/no_image.png"}
                className="img-fluid"
                alt="test"
                style={{ height: "90px", objectFit: "contain" }}
              />
            )}
          </Link>
          <div className="card-body  text-secondary ">
            <p className="card-title  " style={{ fontSize: "12px" }}>
              {title}
            </p>
  
          </div>

        </div>
      </div>
    </div>
  );
};

export default ItemPersonalShope;
