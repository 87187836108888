import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  HeaderProfile,
  ItemPersonalShope,
  LoadingSkeleton,
  SearchBarButton,
  PersonalShopList,
} from "../../components";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdSearch } from "react-icons/io";
import { numberWithCommas } from "../../utils/numberWithCommas";

const CategoryItemPage = () => {
  const state = useLocation();
  const { categoryid } = useParams();
  const [shops, setShops] = useState([]);
  const [loadingShops, setLoadingShops] = useState(true);
  const[isEmptyResp,setEmptyResp] = useState(false)
  // const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    shopsRefreshToken();
    // productsRefreshToken();
  }, []);

  const [sliderRef3] = useKeenSlider({
    rtl: true,
    mode: "free-snap",
    slides: {
      origin: "auto",
      perView: 2.3,
      spacing: 3,
    },
  });
  ///////////
  const shopsRefreshToken = async () => {
    setLoadingShops(true);
    const refresh_Token = await localStorage.getItem("token_refresh");
    try {
      const { data, status } = await axios.post(
        "authentication/token/refresh/",
        {
          refresh: refresh_Token,
        }
      );
      if (status === 200 || status === 201) {
        await localStorage.setItem("token_access", data.access);

        getShops();
      }
    } catch (error) {}
  };

  const getShops = () => {
    let token = localStorage.getItem("token_access");

    axios({
      method: "get",
      url: `returnservicejobbycategory/${categoryid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setLoadingShops(false);
        // setitemCategory = response.data.results.filter(
        //   (item) => item.shopCategory === categoryid
        // );
        if(response.data == ""){
          setEmptyResp(true)
        }else{
          setShops(response.data.reverse());
        }

        // console.log(response.data);
      })
      .catch((error) => {
        setLoadingShops(false);

        if (error.response.status === 401) {
          shopsRefreshToken();
        }
      });
  };

  return (
    <>
      <HeaderProfile title_page="خدمات" />
      <div className="mx-2 mt-2" style={{ marginBottom: "80px" }}>
        <div className="row m-0 g-1">
          {loadingShops ? (
            <>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
              <div className="col-6">
                <LoadingSkeleton height="200px" width="100%" borderRadius={4} />
              </div>
            </>
          ) : (
           
            isEmptyResp ? (<><div className="text-center"><p>در این دسته غرفه ای وجود ندارد <br/> میتوانید اولین نفر باشید <br/> <Link to={`/profile`}> ساخت غرفه جدید </Link></p></div></>):(
            shops?.map((item, index) => (
              <PersonalShopList
                key={index}
                image1={item.image}
                title={item.title}
                // price={numberWithCommas(item.price)}
                to={`/category/shop/${item.id}`}
                // rate={item.average_rating}
              />
            ))
            )
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryItemPage;

const Wapper = styled.section`
  margin-bottom: 79px;
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }

  .card-category {
    background-color: #f5f5f5;
    height: 120px;
    width: 120px;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
  }

  .line {
    height: 0.5rem;
    margin: 0;
    background-color: #ccc;
    border-top: none;
  }
  .text-font-slider {
    color: #b400ae;
    font-size: 1rem;
    font-weight: bold;
  }
  .img-banner3 {
    width: 95%;
    height: 100px;
    object-fit: contain;
  }
  .searchinput:focus {
    outline: none;
  }
  .elevation-on-bottom {
    box-shadow: 0 2px 4px rgb(85 85 85 / 20%);
    padding: 5px 10px 5px 10px;
  }

  .text-input-search {
    border: none;
    width: 100%;
    background-color: transparent;
  }

  .text-input-search:focus-visible {
    border: none;
  }

  .border-search-input {
    position: relative;
    border-radius: 5px;
    background-color: #f5f5f5;
    height: 2.5rem;
  }
`;
